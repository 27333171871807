import React, {useEffect, useState} from 'react';
import { Field, EditView, useHttp } from '@builtbypixel/nucleus';

import setup from './setup';
import {Box, Container} from "@chakra-ui/react";
import {useWatch} from "react-hook-form";

const IFrameRenderer = () => {
    const IFRAME = useWatch({ name: 'iframe' });

    return IFRAME ? (
        <div style={{height: "100vh", width:"100%"}} dangerouslySetInnerHTML={{__html: IFRAME}} />
    ) : null
}

const EditForm = () => {
    const Http = useHttp();
    const [cruiseLines, setCruiseLines] = useState();
    const [rooms, setRooms] = useState();
    const [boardTypes, setBoardTypes] = useState();
    const [facilities, setFacilities] = useState();

    useEffect(() => {
        Http.get('/cruise-lines').then(res => setCruiseLines(res.data.data));
        Http.get('/rooms').then(res => setRooms(res.data.data));
        Http.get('/board-types').then(res => setBoardTypes(res.data.data));
        Http.get('/facilities').then(res => setFacilities(res.data.data));
    }, [Http])

  return (
    <EditView setup={setup} isFullWidth={false}>
      <Container
          p={{ base: '10px', sm: '15px' }}
          w='100%'
          variant='card'
          rounded='none'
          maxWidth='none'
      >
        <Box display='flex' flexDir='row' gridGap='3'>
            <Field
                auto
                isFullWidth
                name="name"
                label="Ship name"
                component="text"
                isRequired
            />
            <Field
                auto
                isFullWidth
                name="cruise_line"
                label="Cruise Line"
                component="select"
                options={cruiseLines}
                valueKey="id"
                labelKey="value"
                isRequired
            />
            <Field
                auto
                isFullWidth
                name="star_rating"
                label="Star Rating"
                component="stars"
                isRequired
            />
        </Box>
          <Box display='flex' flexDir='row' gridGap='3'>
              <Field
                  auto
                  isFullWidth
                  name="slug"
                  label="Slug"
                  component="text"
                  isRequired
              />
          </Box>
          <Box display='flex' flexDir='row' gridGap='3'>
              <Field
                  auto
                  isFullWidth
                  name="summary"
                  label="Summary"
                  component="richtext"
                  isRequired
              />
          </Box>
          <Box display='flex' flexDir='row' gridGap='3'>
              <Field
                  auto
                  isFullWidth
                  name="banner_image"
                  label="Images"
                  component="file"
                  isRequired
              />
          </Box>
          <Box display='flex' flexDir='row' gridGap='3'>
              <Field
                  auto
                  isFullWidth
                  name="rooms"
                  label="Available Room Types"
                  component="select"
                  options={rooms}
                  valueKey="id"
                  labelKey="name"
                  isRequired
                  isMulti
                  isHorizontal
              />
              <Field
                  auto
                  isFullWidth
                  name="board_types"
                  label="Available Board Types"
                  component="select"
                  options={boardTypes}
                  valueKey="id"
                  labelKey="name"
                  isRequired
                  isMulti
                  isHorizontal
              />
          </Box>
          <Box display='flex' flexDir='row' gridGap='3'>
              <Field
                  auto
                  isFullWidth
                  name="facilities"
                  label="Ship Facilities"
                  component="select"
                  options={facilities}
                  valueKey="id"
                  labelKey="name"
                  isRequired
                  isMulti
                  isHorizontal
              />
              <Field
                  auto
                  isFullWidth
                  name="iframe"
                  label="iFrame Content"
                  component="text"
                  isRequired
              />
          </Box>

          <IFrameRenderer />
      </Container>
    </EditView>
  );
};

export default EditForm;
