const modelName = 'Deal Enquiries';
const modelNameSingular = 'Deal Enquiry';
const endpoint = '/deal-enquiry';

const setup = {
  title: modelName,
  singular: modelNameSingular,
  model: modelName.replace(/\s+/g, '-').toLowerCase(),
  canDelete: true,
  canAddNew: false,
  canSearch: true,
  canExport: "deal-enquiry/export",
  endpoint,
  accessor: 'id',
};

export default setup;
