const modelName = 'Callbacks';
const modelNameSingular = 'Callback';
const endpoint = '/callbacks';

const setup = {
  title: modelName,
  singular: modelNameSingular,
  model: modelName.replace(/\s+/g, '-').toLowerCase(),
  canDelete: true,
  canAddNew: false,
  canSearch: true,
  canExport: "callbacks/export",
  endpoint,
  accessor: 'id',
};

export default setup;
