import React, { useMemo } from 'react';
import { LinkButton, ListWrapper } from '@builtbypixel/nucleus';
import { Button } from '@chakra-ui/react';

import setup from './setup';
// Extra Menu appears next to the create button
const extraMenu = () => {
  return null;
};

const columns = [
  {
    Header: 'Name',
    id: 'edit',
    accessor: 'first_name',
    Cell: ({ row, value }) => {
      console.log(row);
      return (
        <LinkButton to={`${setup.model}/edit/${row.original[setup.accessor]}`}>
          <Button colorScheme='blue' variant='link' justifyContent='flex-start'>
            {value} {row.original.last_name}
          </Button>
        </LinkButton>
      );
    },
  },
  {
    Header: 'Email',
    accessor: 'email',
  },
];

const List = () => {
  const filters = useMemo(() => [], []);

  return (
    <ListWrapper
      filters={filters}
      setup={setup}
      columns={columns}
      extraMenu={extraMenu}
      handleRowSelection={rows => console.log(rows)}
    />
  );
};

export default List;
