import React from 'react';
import { LinkButton, ListWrapper } from '@builtbypixel/nucleus';
import { Button } from '@chakra-ui/react';
import moment from 'moment';

import setup from './setup';

const extraMenu = () => null;

const List = () => {
  const columns = [
    {
      Header: 'Ref',
      accessor: 'id',
      id: 'edit',
      Cell: ({ row, value }) => (
        <LinkButton to={`${setup.model}/edit/${row.original.id}`}>
          <Button variant='link' variantColor='secondary' rounded='md'>
            {value}
          </Button>
        </LinkButton>
      ),
    },
    {
      Header: 'First Name',
      accessor: 'enquiry_details.first_name',
    },
    {
      Header: "Last Name",
      accessor: 'enquiry_details.last_name'
    },
    {
      accessor: "created_at",
      Header: "Created",
      Cell: props => moment(props.value).format("LLL")
    }
  ];

  return <ListWrapper setup={setup} columns={columns} extraMenu={extraMenu} />;
};

export default List;
