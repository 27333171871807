import { Box } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { Field, useHttp } from "@builtbypixel/nucleus";

const CruiseLineDetails = () => {
  const Http = useHttp();
  const [multiCenterRegions, setMultiCenterRegions] = useState();

  useEffect(() => {
    Http.get("/multi-center-regions").then((res) =>
      setMultiCenterRegions(res.data.data)
    );
  }, [Http]);

  return (
    <>
      <Box display="flex" flexDir="row" gridGap="3">
        <Field
          auto
          isFullWidth
          name="name"
          component="text"
          isRequired
          label="Cruise Line Name"
        />
        <Field
          auto
          isFullWidth
          name="subtitle"
          component="text"
          label="Sub Title"
        />
      </Box>
      <Box display="flex" flexDir="row" gridGap="3">
        <Field auto isFullWidth name="slug" component="text" label="Slug" />
      </Box>
      <Box display="flex" flexDir="row" gridGap="3">
        <Field
          auto
          isFullWidth
          name="summary"
          component="richtext"
          isRequired
          label="Top Description (Summary)"
        />
      </Box>
      <Box display="flex" flexDir="row" gridGap="3">
        <Field
          auto
          isFullWidth
          name="cruise_summary"
          component="richtext"
          isRequired
          label="Top Description Cruise & Stay (Summary)"
        />
      </Box>
      <Box display="flex" flexDir="row" gridGap="3">
        <Field
          auto
          isFullWidth
          name="description"
          component="richtext"
          isRequired
          label="Description"
        />
      </Box>
      <Box display="flex" flexDir="row" gridGap="3">
        <Field
          auto
          isFullWidth
          name="logo_image"
          component="file"
          label="Logo Image"
        />
      </Box>
      <Box display="flex" flexDir="row" gridGap="3">
        <Field
          auto
          isFullWidth
          name="cruise_description"
          component="richtext"
          isRequired
          label="Description Cruise & Stay"
        />
      </Box>
      <Box display="flex" flexDir="row" gridGap="3">
        <Field
          auto
          isFullWidth
          name="banner_image"
          component="file"
          isRequired
          label="Hero Image"
        />
      </Box>
      <Box display="flex" flexDir="row" gridGap="3">
        <Field
          auto
          isFullWidth
          name="multi_center_region"
          component="select"
          label="Multi Center Region"
          options={multiCenterRegions}
          labelKey="name"
          valueKey="id"
        />
        <Field
          auto
          isFullWidth
          name="video_link"
          component="text"
          label="Video Link"
        />
      </Box>
    </>
  );
};

export default CruiseLineDetails;
