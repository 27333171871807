import React from 'react';
import { EditView, Field } from '@builtbypixel/nucleus';

import setup from './setup';
import {Container, Box} from "@chakra-ui/react";

const EditForm = () => {
  return (
    <EditView setup={setup} isFullWidth={false}>
      <Container
          p={{ base: '10px', sm: '15px' }}
          w='100%'
          variant='card'
          rounded='none'
          maxWidth='none'
      >
          <Box display='flex' flexDir='row' gridGap='3'>
            <Field
                auto
                isFullWidth
                name="first_name"
                label="First Name"
                component="text"
            />
              <Field
                  auto
                  isFullWidth
                  name="last_name"
                  label="Last Name"
                  component="text"
              />
          </Box>
          <Box display='flex' flexDir='row' gridGap='3'>
            <Field
                auto
                isFullWidth
                name="callback_date.time"
                label="Callback Time"
                component="date"
                dateFormat={"dddd d MMMM YYYY h:mm a"}
                showTimeSelect
                timeIntervals={15}
            />
              <Field
                  auto
                  isFullWidth
                  name="email"
                  label="Email Address"
                  component="text"
              />
          </Box>
          <Box display='flex' flexDir='row' gridGap='3'>
              <Field
                  auto
                  isFullWidth
                  name="phone_number"
                  label="Phone number"
                  component="text"
              />
          </Box>

          <Box display='flex' flexDir='row' gridGap='3'>
              <Field
                  auto
                  isFullWidth
                  name="ref"
                  label="Referrer"
                  component="text"
                  disabled
              />
              <Field
                  auto
                  isFullWidth
                  name="gclid"
                  label="GCLID"
                  component="text"
                  disabled
              />
          </Box>

          <Field
              auto
              isFullWidth
              name="comments"
              label="Comments"
              component="richtext"
          />
      </Container>
    </EditView>
  );
};

export default EditForm;
