const modelName = 'Deal Types';
const modelNameSingular = 'Deal Type';
const endpoint = '/deal-types';

const setup = {
  title: modelName,
  singular: modelNameSingular,
  model: modelName.replace(/\s+/g, '-').toLowerCase(),
  canDelete: true,
  canAddNew: true,
  canSearch: true,
  endpoint,
  accessor: 'id',
};

export default setup;
