const modelName = 'Sub Categories';
const modelNameSingular = 'Sub Category';
const endpoint = '/sub-categories';

const setup = {
  title: modelName,
  singular: modelNameSingular,
  model: modelName.replace(/\s+/g, '-').toLowerCase(),
  canDelete: true,
  canAddNew: false,
  canSearch: true,
  endpoint,
  accessor: 'id',
};

export default setup;
