import React from "react";
import { Field } from "@builtbypixel/nucleus";
import Legs from "./Legs";

const Options = ({ searchTags, destinationTags, destinationOptions, hotelOptions, shipOptions, boardTypes }) => {
    return (
                <Field
                    auto
                    isFullWidth
                    component="repeater"
                    withinAccordion
                    layout="vertical"
                    name="options"
                    singleName="Option"
                    fieldArray={[
                        {
                            name: 'name',
                            component: 'text',
                            label: 'Name'
                        },
                        {
                            name: 'description',
                            component: 'richtext',
                            label: 'Description'
                        },
                        {
                            name: 'legs',
                            component: 'repeater',
                            layout: 'vertical',
                            destinationOptions,
                            destinationTags,
                            hotelOptions,
                            boardTypes,
                            searchTags,
                            shipOptions,
                            fieldArray: [{
                                component: Legs,
                            }],
                            singleName: "Leg"
                        }
                    ]}
                />
    )
}

export default Options;