import React from 'react';
import { LinkButton, ListWrapper } from '@builtbypixel/nucleus';
import { Button } from '@chakra-ui/react';

import setup from './setup';
import moment from "moment";

const extraMenu = () => null;

const List = () => {
  const columns = [
    {
      Header: 'ID',
      accessor: 'id',
      id: 'edit',
      Cell: ({ row }) => (
        <LinkButton to={`${setup.model}/edit/${row.original.slug}`}>
          <Button variant='link' variantColor='secondary' rounded='md'>
            {row.original.name}
          </Button>
        </LinkButton>
      ),
    },
    {
      Header: 'First Name',
      accessor: 'first_name',
    },
    {
      Header: 'Last Name',
      accessor: 'last_name'
    },
    {
      Header: "Created",
      accessor: "created_at.date",
      Cell: props => moment(props.value).format("LLL")
    }
  ];

  return <ListWrapper setup={setup} columns={columns} extraMenu={extraMenu} />;
};

export default List;
