import { Box } from '@chakra-ui/react';
import { Field, useHttp } from '@builtbypixel/nucleus';
import React, { useEffect, useState } from 'react';

const BookingInfo = () => {
  const Http = useHttp();
  const [bookingStatuses, setBookingStatuses] = useState([]);

  useEffect(() => {
    Http.get('/booking-statuses').then(res => {
      setBookingStatuses(res.data.data);
    });
  }, [Http]);

  console.log('HERE', bookingStatuses);

  return (
    <>
      <Box display='flex' flexDir='row' gridGap='3'>
        <Field
          auto
          isFullWidth
          name='booking_status'
          component='select'
          label='Booking Status'
          options={bookingStatuses}
          labelKey='name'
          valueKey='id'
        />
        <Field
          auto
          isFullWidth
          name='date_from'
          component='date'
          label='Travel Date'
        />
      </Box>

      <Box display='flex' flexDir='row' gridGap='3'>
        <Field
          auto
          isFullWidth
          name='departure_airport.name'
          component='text'
          label='Departure Airport'
        />
        <Field
          auto
          isFullWidth
          name='people'
          component='number'
          label='Number of Travellers'
        />
      </Box>
      <Box display='flex' flexDir='row' gridGap='3'>
        <Field
          auto
          isFullWidth
          name='deal.duration'
          component='text'
          label='Number of Nights'
        />
        <Field
          auto
          isFullWidth
          name='total_price'
          component='number'
          label='Total Price'
        />
      </Box>

      <Field auto isFullWidth name='notes' component='richtext' label='Notes' />
    </>
  );
};

export default BookingInfo;
