import React from 'react';
import { LinkButton, ListWrapper } from '@builtbypixel/nucleus';
import { Button } from '@chakra-ui/react';
import moment from 'moment';

import setup from './setup';

const extraMenu = () => null;

const List = ({ customSelected }) => {
  const columns = [
    {
      Header: 'Deal ID',
      accessor: 'id',
      id: 'edit',
      Cell: ({ row, value }) => (
        <LinkButton to={`${setup.model}/edit/${row.original[setup.accessor]}`}>
          <Button variant='link' variantColor='secondary' rounded='md'>
            {value}
          </Button>
        </LinkButton>
      ),
    },
    {
      Header: 'Deal Name',
      accessor: 'name',
    },
    {
      Header: 'Cruise Line',
      accessor: 'cruise_line.name',
    },
    {
      Header: 'Created at',
      accessor: 'created_at',
      Cell: ({ value }) => moment(value).format('llll'),
    },
    {
      Header: "",
      accessor: "id",
      Cell: ({ row }) => <a className={"ml-3"} href={`http://viva-cruise.co.uk/deal/${row.original.slug}/${row.original.id}/${row.original.deal_status && row.original.deal_status.name.toLowerCase() === "preview" ? 'preview' : ''}`} target="_blank">
        <Button size="sm" color={"primary"}>
          View
        </Button>
      </a>
    }
  ];

  return (
    <ListWrapper
      setup={setup}
      columns={columns}
      extraMenu={extraMenu}
      customSelected={customSelected}
    />
  );
};

export default List;
