import React, { useState } from 'react';
import {
  Flex,
  Text,
  Box,
  Button,
  Alert,
  Image,
  useColorMode,
  Container,
  AlertIcon,
} from '@chakra-ui/react';
import { Redirect, useHistory } from 'react-router-dom';
import axios from 'axios';
import { useFormContext } from 'react-hook-form';
import { useRecoilValue } from 'recoil';

import LinkButton from '../../../components/LinkButton';
import { Form, Field } from '../../../components/Form';
import { generateSlugFromSite, getSite } from '../../../helpers';
import { authAtom } from '../../../state/auth';

const SubmitButton = ({ loading }) => {
  const { submit } = useFormContext();
  return (
    <Button
      size='lg'
      isFullWidth
      variant='primary'
      mb={3}
      type='submit'
      isLoading={loading}
      onClick={() => submit()}
      loadingText='Logging in securely...'
    >
      Login
    </Button>
  );
};

const Login = () => {
  const { currentUser, loading: authLoading } = useRecoilValue(authAtom);

  const now = new Date();
  const { colorMode } = useColorMode();
  const history = useHistory();
  const referrer = history.location.state
    ? history.location.state.from.pathname
    : getSite
    ? getSite.loginRedirect
    : '/customers';

  // STANDARD
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const apiPath = process.env.REACT_APP_API;
  const prefix = process.env.REACT_APP_API_PREFIX;
  const loginPath = process.env.REACT_APP_LOGIN_PATH;

  const standardLogin = data => {
    setLoading(true);
    axios
      .post(apiPath + '/' + prefix + '/' + loginPath, data)
      .then(res => {
          console.log('logging in...');
          setLoading(false);
          localStorage.setItem(
              `${generateSlugFromSite()}-token`,
              JSON.stringify({
                token: res.data.success.token,
                expiry: now.getTime() + 230400000, // Expires in 8 hours
              })
          );
          if (!referrer) {
            window.location.href = getSite.loginRedirect;
          } else {
            window.location.href = referrer;
          }
      })
      .catch(err => {
        console.log('log in failed');
        setLoading(false);
        setError(err);
      });
  };

  const onSubmit = async data => {
    if (localStorage.getItem(`${generateSlugFromSite()}-token`)) {
      window.location.href = getSite.loginRedirect;
    }

    standardLogin(data);
  };

  if (currentUser && !authLoading) {
    return <Redirect to={referrer || getSite.loginRedirect} />;
  }

  return (
    <Flex
      align='center'
      justify='center'
      w='100vw'
      h='100vh'
      direction='column'
      bg='gray.100'
    >
      <Container
        variant='Container'
        w='100%'
        maxWidth={500}
        rounded='20px'
        py='30px'
        boxShadow='md'
      >
        <Flex px={10} align='center' justify='center' w='100%' mb='20px'>
          <Image
            src={getSite?.clientLogoUrl[colorMode]}
            objectFit='contain'
            size='170px'
            maxHeight='100px'
          />
        </Flex>
        <Flex align='center' direction='column'>
          <Flex
            direction='column'
            align='center'
            justify='center'
            w='100%'
            maxWidth='400px'
          >
            <Text as='h1' fontSize='26px' fontWeight='bold'>
              Login
            </Text>
            <Box py={10} px={5} w='100%'>
              <Form onSubmit={onSubmit}>
                <Field
                  auto={false}
                  isFullWidth
                  name='email'
                  component='text'
                  variant='filled'
                  size='lg'
                  placeholder='Email Address'
                  type='email'
                  autoFocus
                  mb={3}
                  rules={{ required: true }}
                />
                <Field
                  auto={false}
                  isFullWidth
                  name='password'
                  component='text'
                  variant='filled'
                  size='lg'
                  placeholder='Password'
                  type='password'
                  mb={3}
                  rules={{ required: true }}
                />

                <SubmitButton loading={loading} />
              </Form>
              <LinkButton to='forgot-password'>
                <Button variant='ghost' isFullWidth size='lg'>
                  Forgot Password
                </Button>
              </LinkButton>
            </Box>
          </Flex>

          {error &&
            (error.response?.data?.errors ? (
              <Alert status='error' variant='subtle'>
                <AlertIcon />
                {error?.response.data.errors.message}
              </Alert>
            ) : (
              <Alert status='error' variant='subtle'>
                <AlertIcon />
                Error logging in
              </Alert>
            ))}
        </Flex>
      </Container>
    </Flex>
  );
};
export default Login;
