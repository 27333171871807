import { createCrudRoutes } from '@builtbypixel/nucleus';

import List from './List';
import setup from './setup';

import Edit from './';

const crud = createCrudRoutes({
  setup,
  edit: Edit,
  list: List,
  name: setup.title,
  view: null,
});

const otherRoutes = [
  /*
        {
        path: '/login',
        title: 'Login',
        exact: true,
        auth: false,
        component: Login,
    },
    */
];

const routes = [...crud, ...otherRoutes];

export default routes;
