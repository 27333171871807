import { cloneElement } from 'react';
import { useHistory } from 'react-router-dom';

const LinkButton = props => {
  const history = useHistory();
  return cloneElement(props.children, {
    onClick: () =>
      props.target === '_blank'
        ? window.open(props.to, '_blank')
        : history.push(props.to),
  });
};

export default LinkButton;
