import React, { useMemo } from 'react';
import { AutoFields, EditView } from '@builtbypixel/nucleus';

import setup from './setup';

const EditForm = () => {
  const fields = useMemo(
    () => [
      {
        group: '',
        fields: [
          {
            name: 'first_name',
            component: 'text',
            placeholder: 'First Name',
            label: 'First Name',
          },
          {
            name: 'last_name',
            component: 'text',
            placeholder: 'Last Name',
            label: 'Last Name',
          },
          {
            name: 'phone_number',
            component: 'text',
            placeholder: '+44 7252826867',
            label: 'Phone',
          },
          {
            name: 'email',
            component: 'text',
            placeholder: 'test@test.com',
            label: 'Email',
          },
          {
            name: 'verified',
            component: 'switch',
            label: 'Verified',
          },
          {
            name: 'password',
            component: 'text',
            label: 'Password',
            createOnly: true,
          },
          {
            name: 'password_confirm',
            component: 'text',
            label: 'Confirm Password',
            createOnly: true,
          },
        ],
      },
    ],
    []
  );

  return (
    <EditView setup={setup} isFullWidth={false}>
      <AutoFields fields={fields} />
    </EditView>
  );
};

export default EditForm;
