const modelName = 'Holiday Enquiries';
const modelNameSingular = 'Holiday Enquiry';
const endpoint = '/holiday-enquiries';

const setup = {
  title: modelName,
  singular: modelNameSingular,
  model: modelName.replace(/\s+/g, '-').toLowerCase(),
  canDelete: true,
  canAddNew: false,
  canSearch: true,
  canExport: "holiday-enquiries/export",
  endpoint,
  accessor: 'id',
};

export default setup;
