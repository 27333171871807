import React from 'react';
import { NucleusCore } from '@builtbypixel/nucleus';

import config from './config.json';
import routes from './routes';
import links from './links';

const App = () => {
  return <NucleusCore config={config} routes={routes} links={links} />;
};

export default App;
