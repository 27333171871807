import { Field, useHttp } from "@builtbypixel/nucleus";
import React, { useEffect, useState } from "react";
import { Box } from "@chakra-ui/react";
import {useForm, useWatch} from "react-hook-form";
const Details = ({ searchTags }) => {
  const Http = useHttp();
  const [dealStatuses, setDealStatuses] = useState();
  const [categories, setCategories] = useState();
  const [subCategories, setSubCategories] = useState();
  const [dealTypes, setDealTypes] = useState();
  const [cruiseLines, setCruiseLines] = useState();
  const [ships, setShips] = useState();

  const { id } = useWatch({ name: "cruise_line" }) ?? { id: null };

  useEffect(() => {
    Http.get("/top-level-categories").then((res) =>
      setCategories(res.data.data)
    );
    Http.get("/sub-categories").then((res) => setSubCategories(res.data.data));
    Http.get("/deal-statuses").then((res) => setDealStatuses(res.data.data));
    Http.get("/deal-types").then((res) => setDealTypes(res.data.data));
    Http.get("/cruise-lines").then((res) => setCruiseLines(res.data.data));
  }, [Http]);
  useEffect(() => {
    if (id) {
      Http.get(`/cruise-line-ships/${id}`).then((res) =>
        setShips(res.data.data)
      );
    }
  }, [id]);

  return (
    <>
      <Box display="flex" flexDir="row" gridGap="3">
        <Field
          auto
          isFullWidth
          name="deal_status"
          component="select"
          label="Deal Status"
          options={dealStatuses}
          labelKey="name"
          valueKey="id"
        />
        <Field
          auto
          isFullWidth
          name="search_tags"
          component="select"
          label="Search Tags"
          options={searchTags}
          valueKey="id"
          labelKey="value"
          isMulti
          isHorizontal
        />
      </Box>

      <Box display="flex" flexDir="row" gridGap="3">
        <Field
          auto
          isFullWidth
          name="top_level_categories"
          component="select"
          label="Category"
          options={categories}
          labelKey="name"
          valueKey="id"
          isMulti
          isHorizontal
        />
        <Field
          auto
          isFullWidth
          name="sub_categories"
          component="select"
          label="Sub Category"
          options={subCategories}
          valueKey="id"
          labelKey="name"
          isMulti
          isHorizontal
        />
      </Box>

      <Box display="flex" flexDir="row" gridGap="3">
        <Field
          auto
          isFullWidth
          name="name"
          component="text"
          label="Deal Name"
        />
        <Field
          auto
          isFullWidth
          name="sub_title"
          component="text"
          label="Sub Title"
        />
      </Box>

      <Box display="flex" flexDir="row" gridGap="3">
        <Field
          auto
          isFullWidth
          name="deal_type"
          component="select"
          label="tags"
          labelKey="name"
          valueKey="id"
          options={dealTypes}
        />
        <Field
          auto
          isFullWidth
          name="book_online"
          component="switch"
          label="Bookable online"
        />
      </Box>

      <Box display="flex" flexDir="row" gridGap="3">
        <Field
          auto
          isFullWidth
          name="peace_of_mind"
          component="switch"
          label="Book with confidence"
        />
        <Field
          auto
          isFullWidth
          name="duration"
          component="number"
          label="Nights"
        />
      </Box>
      <Box display="flex" flexDir="row" gridGap="3">
        <Field
          auto
          isFullWidth
          name="price_from"
          component="text"
          label="Price From"
        />
        <Field
          auto
          isFullWidth
          name="deposit"
          component="text"
          label="Deposit"
        />
      </Box>
      <Box display="flex" flexDir="row" gridGap="3">
        <Field
          auto
          isFullWidth
          name="cruise_line"
          component="select"
          label="Cruise Line Name"
          options={cruiseLines}
          labelKey="name"
          valueKey="id"
        />
        <Field
          auto
          isFullWidth
          name="ship"
          component="select"
          label="Ship Name"
          options={ships}
          labelKey="name"
          valueKey="id"
        />
      </Box>

      <Box display="flex" flexDir="row" gridGap="3">
        <Field
          auto
          isFullWidth
          name="banner_image"
          component="file"
          label="Hero Image"
        />
      </Box>

      <Field
        auto
        isFullWidth
        name="summary"
        component="richtext"
        label="Summary"
      />

      <Field
        auto
        isFullWidth
        name="fine_print"
        component="richtext"
        label="Fine Print"
      />

      <Field
        auto
        isFullWidth
        name="exclusions"
        component="richtext"
        label="Exclusions"
      />

      <Field
        auto
        isFullWidth
        name="trip_advisor_script"
        component="text"
        label="Trip advisor script"
      />

      <Field
        auto
        isFullWidth
        name="redirect_url"
        component="text"
        label="Redirect url"
      />
    </>
  );
};

export default Details;
