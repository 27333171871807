import React from 'react';
import { Field, EditView } from '@builtbypixel/nucleus';

import setup from './setup';
import {Container} from "@chakra-ui/react";

const EditForm = () => {
  return (
    <EditView setup={setup} isFullWidth={false} isSingle>
      <Container
          p={{ base: '10px', sm: '15px' }}
          w='100%'
          variant='card'
          rounded='none'
          maxWidth='none'
      >
          <Field auto isFullWidth name="name" component="text" label="Title" />
          <Field auto isFullWidth name="banner_image" component="file" label="Hero Image" />
          <Field auto isFullWidth name="summary" component="richtext" label="Summary" />
          <Field auto isFullWidth name="description" component="richtext" label="Description" />
      </Container>
    </EditView>
  );
};

export default EditForm;
