import {Flex} from "@chakra-ui/react";
import { Field } from "@builtbypixel/nucleus";

const ImgPara = (isReverse) => ({ name }) => {
    return (
        <Flex flexDirection={isReverse ? "column-reverse" : "column"}>
            <Field
                auto
                isFullWidth
                name={`${name}.image`}
                component="file"
            />
            <Field
                auto
                isFullWidth
                name={`${name}.content`}
                component="richtext"
            />
        </Flex>
    )
}

export default ImgPara;