import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import {MdDragHandle} from "react-icons/md";

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    padding: grid * 2,
    margin: `0 0 ${grid}px 0`,
    color: 'white',
    // change background colour if dragging
    background: isDragging ? "lightgreen" : "grey",
    display: 'flex',
    flexDirection: "row",
    alignItems: 'center',
    gridGap: '10px',
    // styles we need to apply on draggables
    ...draggableStyle
});

const getListStyle = isDraggingOver => ({
    background: isDraggingOver ? "lightblue" : "lightgrey",
    padding: grid
});

const Order = ({ value, onChange, labelKey }) => {
    const onDragEnd = (result) => {
        if (!result.destination) {
            return;
        }

        const items = reorder(
            value,
            result.source.index,
            result.destination.index
        );

        onChange(items);
    }

    return (
        <div>
            <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="droppable">
                    {(provided, snapshot) => (
                        <div
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                            style={getListStyle(snapshot.isDraggingOver)}
                        >
                            {value.map((item, index) => (
                                <Draggable key={item.id} draggableId={`${item.id}`} index={index}>
                                    {(provided, snapshot) => (
                                        <div
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            style={getItemStyle(
                                                snapshot.isDragging,
                                                provided.draggableProps.style
                                            )}
                                        >
                                            <MdDragHandle /> {item[labelKey]}
                                        </div>
                                    )}
                                </Draggable>
                            ))}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>
        </div>
    )
}

export default Order;