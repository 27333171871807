const modelName = 'Administrators';
const modelNameSingular = 'Administrator';
const endpoint = '/admin-users';

const setup = {
  title: modelName,
  singular: modelNameSingular,
  model: modelName.replace(/\s+/g, '-').toLowerCase(),
  canDelete: true,
  canAddNew: true,
  canSearch: true,
  endpoint,
  accessor: 'id',
};

export default setup;
