import React, {useEffect, useState} from 'react';
import { EditView, useHttp } from '@builtbypixel/nucleus';
import { Flex, Tabs, TabPanels, TabPanel } from '@chakra-ui/react';

import Menu from './Menu';
import Details from './components/Details';
import Options from './components/Options';
import Dates from './components/Dates';
import setup from './setup';
import ReviewsTable from "../../components/reviewsTable";

const EditForm = () => {
  const Http = useHttp();
  const [searchTags, setSearchTags] = useState();
  const [destinationOptions, setDestinationOptions] = useState();
  const [destinationTags, setDestinationTags] = useState();
  const [hotelOptions, setHotelOptions] = useState();
  const [boardTypes, setBoardTypes] = useState();
  const [shipOptions, setShipOptions] = useState();

  useEffect(() => {
    Http.get('/destinations').then(res => setDestinationOptions(res.data.data));
    Http.get('/tags').then(res => setDestinationTags(res.data.data));
    Http.get('/hotels').then(res => setHotelOptions(res.data.data));
    Http.get('/board-types').then(res => setBoardTypes(res.data.data));
    Http.get('/ships').then(res => setShipOptions(res.data.data));
    Http.get('/search-tags').then(res => setSearchTags(res.data.data));
  }, [Http]);

  return (
    <EditView setup={setup} isFullWidth={false}>
      <Flex w='100%' minHeight='100vh' bg='white'>
        <Tabs flex={1} minHeight='100vh' isLazy>
          <Menu />
          <TabPanels>
            <TabPanel>
              <Details searchTags={searchTags} />
            </TabPanel>
            <TabPanel>
              <Options
                  searchTags={searchTags}
                  destinationOptions={destinationOptions}
                  destinationTags={destinationTags}
                  hotelOptions={hotelOptions}
                  boardTypes={boardTypes}
                  shipOptions={shipOptions}
              />
            </TabPanel>
            <TabPanel>
              <Dates />
            </TabPanel>
            <TabPanel>
              <ReviewsTable />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Flex>
    </EditView>
  );
};

export default EditForm;
