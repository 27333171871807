import React, {useEffect, useState} from 'react';
import { Field, EditView, useHttp } from '@builtbypixel/nucleus';

import setup from './setup';
import {Box, Container} from "@chakra-ui/react";

const EditForm = () => {
  const Http = useHttp();
  const [enquiryStatuses, setEnquiryStatuses] = useState();

  useEffect(() => {
    Http.get('/enquiry-statuses').then(res => setEnquiryStatuses(res.data.data));
  }, [Http]);

  return (
    <EditView setup={setup} isFullWidth={false}>
      <Container
          p={{ base: '10px', sm: '15px' }}
          w='100%'
          variant='card'
          rounded='none'
          maxWidth='none'
      >
        <Box display='flex' flexDir='row' gridGap='3'>
            <Field
              auto
              isFullWidth
              name="enquiry_status"
              component="select"
              label="Status"
              labelKey="name"
              valueKey="id"
              options={enquiryStatuses}
            />
        </Box>

        <Box display='flex' flexDir='row' gridGap='3'>
          <Field
              auto
              isFullWidth
              name="first_name"
              component="text"
              label="First Name"
              disabled
          />
          <Field
              auto
              isFullWidth
              name="last_name"
              component="text"
              label="Last Name"
              disabled
          />
        </Box>

        <Box display='flex' flexDir='row' gridGap='3'>
          <Field
              auto
              isFullWidth
              name="deal_name"
              component="text"
              label="Deal Name"
              disabled
          />
          <Field
              auto
              isFullWidth
              name="departure_date"
              component="date"
              label="Departure Date"
              dateFormat="ddd d MMM YYYY"
              disabled
          />
        </Box>

        <Box display='flex' flexDir='row' gridGap='3'>
          <Field
              auto
              isFullWidth
              name="number_of_people"
              component="number"
              label="Travellers"
              disabled
          />
          <Field
              auto
              isFullWidth
              name="option_number"
              component="number"
              label="Option Number"
              disabled
          />
        </Box>

        <Box display='flex' flexDir='row' gridGap='3'>
          <Field
              auto
              isFullWidth
              name="cabin_type"
              component="text"
              label="Cabin Type"
              disabled
          />
          <Field
              auto
              isFullWidth
              name="phone_number"
              component="text"
              label="Phone number"
              disabled
          />
        </Box>

        <Box display='flex' flexDir='row' gridGap='3'>
          <Field
              auto
              isFullWidth
              name="email"
              component="text"
              label="Email"
              disabled
          />
        </Box>

        <Box display='flex' flexDir='row' gridGap='3'>
          <Field
              auto
              isFullWidth
              name="comments"
              component="richtext"
              label="Comments"
              disabled
          />
        </Box>

        <Box display='flex' flexDir='row' gridGap='3'>
          <Field
              auto
              isFullWidth
              name="total_price"
              component="text"
              label="Total Price"
              disabled
          />
          <Field
              auto
              isFullWidth
              name="callback_date"
              component="date"
              label="Callback Time"
              disabled
              dateFormat="ddd d MMM YYYY h:mm a"
          />
        </Box>

        <Box display='flex' flexDir='row' gridGap='3'>
          <Field
              auto
              isFullWidth
              name="ref"
              label="Referrer"
              component="text"
              disabled
          />
          <Field
              auto
              isFullWidth
              name="gclid"
              label="GCLID"
              component="text"
              disabled
          />
        </Box>

      </Container>
    </EditView>
  );
};

export default EditForm;
