import {Box} from "@chakra-ui/react";
import { Field } from "@builtbypixel/nucleus";

const DestinationLeg = ({ name, destinationOptions, destinationTags, hotelOptions, boardTypes }) => {
    return (
        <>
            <Field
                auto
                isFullWidth
                component="select"
                name={`${name}.leg_detail.destination`}
                label="Destination"
                options={destinationOptions}
                labelKey="label"
                valueKey="id"
            />

            <Box display='flex' flexDir='row' gridGap='3'>
                <Field
                    auto
                    isFullWidth
                    component="select"
                    name={`${name}.leg_detail.tags`}
                    label="Destination Tags"
                    options={destinationTags}
                    labelKey="value"
                    valueKey="id"
                    isMulti
                    isHorizontal
                />

                <Field
                    auto
                    isFullWidth
                    component="text"
                    name={`${name}.leg_detail.day_from_to`}
                    label="Day From - To"
                />
            </Box>

            <Field
                auto
                isFullWidth
                component="richtext"
                name={`${name}.leg_detail.summary`}
                label="Summary"
            />

            <Field
                auto
                isFullWidth
                component="file"
                name={`${name}.leg_detail.images`}
                label="Images"
            />

            <Box display='flex' flexDir='row' gridGap='3'>
                <Field
                    auto
                    isFullWidth
                    component="select"
                    name={`${name}.leg_detail.hotel`}
                    label="Hotel"
                    options={hotelOptions}
                    labelKey="name"
                    valueKey="id"
                />

                <Field
                    auto
                    isFullWidth
                    component="select"
                    name={`${name}.leg_detail.hotel_room_type`}
                    label="Board Type"
                    options={boardTypes}
                    labelKey="name"
                    valueKey="id"
                />
            </Box>

        </>
    )
};

export default DestinationLeg;