import { Field, useHttp } from "@builtbypixel/nucleus";
import {useEffect, useState} from "react";

const Activities = () => {
    const Http = useHttp();

    const [activityCategories, setActivityCategories] = useState();

    useEffect(() => {
        Http.get('/activity-categories').then(res => setActivityCategories(res.data.data));
    }, [Http])

    return (
        <Field
            name="activities"
            component="repeater"
            singleName="Activity"
            layout="vertical"
            fieldArray={[{
                label: "Activity Title",
                component: "text",
                name: "name"
            },
                {
                    label: "Activity Category",
                    component: "select",
                    options: activityCategories,
                    name: "category",
                    labelKey: "name",
                    valueKey: "id"
                },{
                label: 'Activity Subheading',
                    component: 'text',
                    name: 'heading'
                },{
                    label: 'Image',
                    component: 'file',
                    name: 'image'
                }, {
                    label: 'Paragraph',
                    component: 'richtext',
                    name: 'description'
                }]}
        />
    )
}

export default Activities;