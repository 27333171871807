import React, {useEffect, useState} from 'react';
import { Field, EditView, useHttp } from '@builtbypixel/nucleus';

import setup from './setup';
import {Container, Box} from "@chakra-ui/react";

const EditForm = () => {
  const Http = useHttp();
  const [destinationOptions, setDestinationOptions] = useState();

  useEffect(() => {
    Http.get('/destinations').then(res => setDestinationOptions(res.data.data));
  }, [Http]);


  return (
    <EditView setup={setup} isFullWidth={false}>
      <Container
          p={{ base: '10px', sm: '15px' }}
          w='100%'
          variant='card'
          rounded='none'
          maxWidth='none'
      >
        <Box display='flex' flexDir='row' gridGap='3'>
          <Field
            auto
            isFullWidth
            component="text"
            name="first_name"
            label="First Name"
            disabled
          />
          <Field
            auto
            isFullWidth
            component="text"
            name="last_name"
            label="Last Name"
            disabled
          />
        </Box>

        <Box display='flex' flexDir='row' gridGap='3'>
          <Field
            auto
            isFullWidth
            component="text"
            name="email"
            label="Email"
            disabled
          />
          <Field
            auto
            isFullWidth
            component="text"
            name="phone_number"
            label="Phone number"
            disabled
          />
        </Box>

          <Field
            auto
            isFullWidth
            component="select"
            name="destinations"
            label="Destinations"
            options={destinationOptions}
            labelKey="label"
            valueKey="id"
            isMulti
            isHorizontal
            disabled
          />

        <Box display='flex' flexDir='row' gridGap='3'>
          <Field
              auto
              isFullWidth
              component="text"
              name="price_range.min"
              label="Min Price (per person)"
              disabled
          />
          <Field
              auto
              isFullWidth
              component="text"
              name="price_range.max"
              label="Max Price (per person)"
              disabled
          />
        </Box>

        <Box display='flex' flexDir='row' gridGap='3'>
          <Field
              auto
              isFullWidth
              component="select"
              name="departure_airport"
              label="Departure Airport"
              disabled
              isMulti
              isHorizontal
              labelKey="name"
              valueKey="id"
          />
          <Field
              auto
              isFullWidth
              component="number"
              name="nights"
              label="Nights"
              disabled
          />
        </Box>

        <Box display='flex' flexDir='row' gridGap='3'>
          <Field
              auto
              isFullWidth
              dateFormat={"ddd d MMM YYYY"}
              component="date"
              name="start_date.date"
              label="Start Date"
              disabled
          />
          <Field
              auto
              isFullWidth
              component="date"
              name="end_date.date"
              label="End Date"
              dateFormat={"ddd d MMM YYYY"}
              disabled
          />
        </Box>

        <Field
            auto
            isFullWidth
            component="text"
            name="hduhau"
            label="How did you hear about us?"
            disabled
        />

        <Box display='flex' flexDir='row' gridGap='3'>
          <Field
              auto
              isFullWidth
              name="ref"
              label="Referrer"
              component="text"
              disabled
          />
          <Field
              auto
              isFullWidth
              name="gclid"
              label="GCLID"
              component="text"
              disabled
          />
        </Box>

      </Container>
    </EditView>
  );
};

export default EditForm;
