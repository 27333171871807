import {Box} from "@chakra-ui/react";
import { Field } from "@builtbypixel/nucleus";

const DestinationLeg = ({ name, destinationOptions, destinationTags, hotelOptions, boardTypes }) => {
    return (
        <>
            <Field
                auto
                isFullWidth
                component="select"
                name={`${name}.leg_detail.destination`}
                label="Destination"
                options={destinationOptions}
                labelKey="label"
                valueKey="id"
            />

            <Box display='flex' flexDir='row' gridGap='3'>
                <Field
                    auto
                    isFullWidth
                    component="text"
                    name={`${name}.leg_detail.title`}
                    label="Title"
                />

                <Field
                    auto
                    isFullWidth
                    component="text"
                    name={`${name}.leg_detail.sub_title`}
                    label="Sub Title"
                />
            </Box>

            <Box display='flex' flexDir='row' gridGap='3'>
                <Field
                    auto
                    isFullWidth
                    component="select"
                    name={`${name}.leg_detail.tags`}
                    label="Destination Tags"
                    options={destinationTags}
                    labelKey="value"
                    valueKey="id"
                    isMulti
                    isHorizontal
                />

                <Field
                    auto
                    isFullWidth
                    component="text"
                    name={`${name}.leg_detail.day_from_to`}
                    label="Day From - To"
                />
            </Box>

            <Field
                auto
                isFullWidth
                component="richtext"
                name={`${name}.leg_detail.summary`}
                label="Summary"
            />

            <Field
                auto
                isFullWidth
                component="file"
                name={`${name}.leg_detail.images`}
                label="Images"
            />


            <Field
                auto
                isFullWidth
                component="repeater"
                name={`${name}.guide`}
                label="Itinerary"
                fieldArray={[{
                    name: "title",
                    component: "text",
                    label: "Title"
                },{
                    name: "day_from_to",
                    component: "text",
                    label: "Day From - To"
                }]}
                singleName="Item"
            />
        </>
    )
};

export default DestinationLeg;