import React from "react";
import { Field } from "@builtbypixel/nucleus";

const OrderDestinations = () => {
    return (
        <Field
            name="destinations"
            component="order"
            labelKey="name"
        />
    )
}

export default OrderDestinations;