import React from 'react';
import { Field } from '@builtbypixel/nucleus';

const DocumentInfo = () => {
  return (
    <>
      <br />
      <h2>Document Details</h2>
      <br />

      <Field
        auto
        isFullWidth
        name='documents'
        component='file'
        label='Documents'
      />
    </>
  );
};

export default DocumentInfo;
