import PlacesAutocomplete, {
    geocodeByAddress,
    getLatLng,
} from 'react-places-autocomplete';
import {FaMapMarker} from "react-icons/fa";
import './address.css';
import {useEffect, useState} from "react";

const Places = ({ onChange, value }) => {
    const [address, setAddress] = useState();
    const [location, setLocation] = useState();

    useEffect(() => {
        setAddress(value.address);
        setLocation(value.latLng);
    }, [value])
    const renderSuggestion = ({ suggestion }) => (
        <div className="address-result">
            <FaMapMarker />
            {suggestion}
        </div>
    );

    const handleSelect = (address) => {
        geocodeByAddress(address)
            .then(results => getLatLng(results[0]))
            .then(latLng => {
                setLocation(latLng);
                onChange({
                    address,
                    latLng
                })
            })
            .catch(err => console.error("Error", err))
    }

    const handleChange = (address) => {
        setAddress(address);
    }

    const inputProps = {
        value: address,
        onChange: handleChange
    }

    const cssClasses = {
        input: "chakra-input",
        autocompleteContainer: "address-result-container",
        root: "address-wrapper"
    };

    console.log(inputProps, value)

    return (
        <div>
        <PlacesAutocomplete
            classNames={cssClasses}
            inputProps={inputProps}
            renderSuggestion={renderSuggestion}
            onSelect={handleSelect}
        />
            {location && (
                <div className="location-result">
                    <code>
                        {location.lat} {", "}
                        {location.lng}
                    </code>
                </div>
            )}
        </div>
    )
}

export default Places;