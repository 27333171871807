import React, { memo } from 'react';
import { Stack, Text, Button, Box } from '@chakra-ui/react';
import { BiAddToQueue } from 'react-icons/bi';
import { useParams } from 'react-router-dom';

import LinkButton from '../LinkButton';

const MoreOptions = memo(({ setup }) => {
  const params = useParams();

  return (
    <Stack isInline spacing='4px' mr='10px'>
      <Box>
        {params.type !== 'create' && setup.canAddNew && (
          <LinkButton to={`/${setup.model}/create`}>
            <Button
              variant='outline'
              colorScheme='primary'
              leftIcon={<BiAddToQueue />}
            >
              <Text display={{ xs: 'none', sm: 'inline-block' }}>
                Create New
              </Text>
            </Button>
          </LinkButton>
        )}
      </Box>
    </Stack>
  );
});

export default MoreOptions;
