import React, { useContext, useEffect, useState } from 'react';
import {
  Box,
  Button,
  Flex,
  IconButton,
  Icon,
  Text,
  Image as NucleusImage,
} from '@chakra-ui/react';
import { Image, Transformation } from 'cloudinary-react';
import { useRecoilState } from 'recoil';
import { FiTrash } from 'react-icons/fi';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { CgMenuGridO } from 'react-icons/cg';
import { HiCheck } from 'react-icons/hi';
import { MdPictureAsPdf } from 'react-icons/md';

import PopConfirm from '../../../components/PopConfirm';
import ImagePreview from '../../../components/common/ImagePreview';
import { mediaAtom as _mediaAtom } from '../../../state/media';
import { CloudinaryUploadContext } from '../../../Core/Kernel';

const MediaLibraryComponent = ({
  onChange,
  name,
  modalButton,
  max_files,
  multiple,
  showOptions,
  isRepeater,
  value,
  max,
  ...rest
}) => {
  const { show } = useContext(CloudinaryUploadContext);

  const [selectedFiles, setSelectedFiles] = useState([]);
  const [mediaAtom, setMediaAtom] = useRecoilState(_mediaAtom);

  useEffect(() => {
    const resetAtom = () => {
      const atom = [...mediaAtom];
      const index = atom.findIndex(e => e.name === name);
      atom[index] = { name: name, files: [] };
      setMediaAtom(atom);
    };

    const active = localStorage.getItem('activeMediaField');
    const field = mediaAtom.find(f => f.name === name);

    if (name === active && field && field.files.length !== 0) {
      setSelectedFiles([...selectedFiles, ...field.files]);
      onChange([...selectedFiles, ...field.files]);
      resetAtom();
    }
  }, [mediaAtom, name, onChange, selectedFiles, setMediaAtom]);

  useEffect(() => {
    if (value) {
      setSelectedFiles(value);
    }
  }, [value]);

  const onDragEnd = result => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = reorder(
      selectedFiles,
      result.source.index,
      result.destination.index
    );

    setSelectedFiles(items);
    onChange(items);
  };

  const removeImage = index => {
    const _uploaded = [...selectedFiles];
    _uploaded.splice(index, 1);
    setSelectedFiles(_uploaded);
    onChange(_uploaded);
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  return (
    <React.Fragment>
      <Flex>
        <Button
          size='sm'
          variant='outline'
          colorScheme='secondary'
          onClick={() => show(name)}
        >
          Select file(s)
        </Button>
      </Flex>

      <Box w='100%'>
        {selectedFiles && selectedFiles.length !== 0 && (
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId='droppable'>
              {(provided, snapshot) => (
                <Box
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  w='100%'
                  mt='10px'
                  mb='10px'
                >
                  {selectedFiles &&
                    selectedFiles.length !== 0 &&
                    selectedFiles.map((item, index) => (
                      <Draggable
                        key={`file-${index}`}
                        draggableId={
                          item?.original_filename
                            ? item?.original_filename
                            : `file-${index}`
                        }
                        index={index}
                      >
                        {(provided, snapshot) => (
                          <Flex
                            ref={provided.innerRef}
                            align='center'
                            justify='flex-start'
                            {...provided.draggableProps}
                            {...getItemStyle(
                              snapshot.isDragging,
                              provided.draggableProps.style
                            )}
                          >
                            <Box {...provided.draggableProps}>
                              <CgMenuGridO color='global.text' />
                            </Box>

                            {item?.type === 'application/pdf' ||
                            item?.format === 'application/pdf' ? (
                              <Icon
                                px='6px'
                                fontSize='21px'
                                h='37px'
                                display='flex'
                                alignItems='center'
                                color='secondary'
                              >
                                <MdPictureAsPdf />
                              </Icon>
                            ) : (
                              <ImagePreview
                                url={item?.url}
                                public_id={item?.public_id}
                                w='60px'
                                flexShrink={0}
                              >
                                <Box
                                  w={isRepeater ? '40px' : '60px'}
                                  h='100%'
                                  flexShrink={0}
                                  mr='15px'
                                  objectFit='cover'
                                >
                                  {item?.public_id ? (
                                    <Image
                                      publicId={item?.public_id}
                                      secure='true'
                                    >
                                      <Transformation
                                        width='100'
                                        height='100'
                                        crop='thumb'
                                        fetchFormat='auto'
                                      />
                                    </Image>
                                  ) : (
                                    <NucleusImage
                                      src={item?.url}
                                      boxSize={isRepeater ? '40px' : '60px'}
                                      objectFit='cover'
                                    />
                                  )}
                                </Box>
                              </ImagePreview>
                            )}
                            {!isRepeater && (
                              <Flex direction='column'>
                                <Text
                                  maxWidth={{
                                    xs: '120px',
                                    md: '200px',
                                  }}
                                  fontSize={{
                                    xs: '11px',
                                    sm: '12px',
                                  }}
                                  opacity={0.7}
                                  isTruncated
                                >
                                  {item?.original_filename}
                                </Text>
                                <Text fontWeight='semibold' fontSize='11px'>
                                  {item?.format}
                                </Text>
                              </Flex>
                            )}
                            <Flex as='span' ml='auto' align='center'>
                              <Icon color='success' mr='0px' fontSize='20px'>
                                <HiCheck />
                              </Icon>

                              <PopConfirm
                                onConfirm={() => removeImage(index)}
                                title='Are you sure you want to remove this image?'
                              >
                                <IconButton
                                  variant='link'
                                  ml='5px'
                                  icon={<FiTrash />}
                                />
                              </PopConfirm>
                            </Flex>
                          </Flex>
                        )}
                      </Draggable>
                    ))}
                  {provided.placeholder}
                </Box>
              )}
            </Droppable>
          </DragDropContext>
        )}
      </Box>
    </React.Fragment>
  );
};

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  mb: '5px',
  rounded: 'lg',
  boxShadow: 'sm',
  border: '1px',
  borderColor: isDragging ? 'primary' : 'global.borderColour',
  bg: isDragging ? 'global.elementBgAlt' : 'global.elementBg',
  pr: '10px',
  ...draggableStyle,
});

export default MediaLibraryComponent;
