import React from 'react';
import PropTypes from 'prop-types';

import Table from './Table';

const PlasmaTable = props => {
  const { data, columns } = props;

  const tableData = React.useMemo(() => data, [data]);

  const tableColumns = React.useMemo(() => columns, [columns]);

  return <Table data={tableData} columns={tableColumns} {...props} />;
};

PlasmaTable.propTypes = {
  /**
   * The columns of the table
   */
  columns: PropTypes.array,
  /**
   * If the table has error status
   */
  error: PropTypes.bool,
  /**
   * If the table has loading status
   */
  loading: PropTypes.bool,
  /**
   *  <code>(selected) =></code> The function on selection of a row. If no function declared, checkboxes will be hidden
   */
  onRowSelect: PropTypes.func,
  /**
   * If you need server side pagination.  Set to false if you want automatic local pagination
   */
  manualPagination: PropTypes.bool,
  /**
   * If you need to hide the pagination of the table
   */
  hidePagination: PropTypes.bool,
  /**
   * If you need to make the rows expandable.  Note - each row must have a 'subRows' array which match the column names
   */
  isExpandable: PropTypes.bool,
};

export default PlasmaTable;
