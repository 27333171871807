import React from 'react';
import { Field, EditView } from '@builtbypixel/nucleus';

import setup from './setup';
import {Box, Container} from "@chakra-ui/react";

const EditForm = () => {
  return (
    <EditView setup={setup} isFullWidth={false}>
      <Container
          p={{ base: '10px', sm: '15px' }}
          w='100%'
          variant='card'
          rounded='none'
          maxWidth='none'
      >
        <Box display='flex' flexDir='row' gridGap='3'>
            <Field
              auto
              isFullWidth
              name="first_name"
              component="text"
              label="First Name"
            />
            <Field
              auto
              isFullWidth
              name="last_name"
              component="text"
              label="Last Name"
            />
        </Box>

          <Box display='flex' flexDir='row' gridGap='3'>
            <Field
              auto
              isFullWidth
              name="price"
              component="text"
              label="Price"
            />
            <Field
              auto
              isFullWidth
              name="email"
              component="text"
              label="Email address"
            />
        </Box>

          <Box display='flex' flexDir='row' gridGap='3'>
              <Field
                  auto
                  isFullWidth
                  name="booking_id"
                  component="text"
                  label="Booking ID"
              />
              <Field
                  auto
                  isFullWidth
                  name="site"
                  component="select"
                  options={[
                      { id: 1, name: 'Viva Holidays' },
                      { id: 2, name: 'Viva Cruise' },
                      { id: 3, name: 'Weekender Breaks' },
                  ]}
                  label="Site"
                  labelKey="name"
                  valueKey="id"
              />
          </Box>
          <Box display='flex' flexDir='row' gridGap='3'>
              <Field
                  auto
                  isFullWidth
                  name="status_id.name"
                  component="text"
                  label="Status"
                  disabled
              />
              <Field
                  auto
                  isFullWidth
                  name="payment_link"
                  component="text"
                  label="Payment Link"
                  disabled
              />
          </Box>
      </Container>
    </EditView>
  );
};

export default EditForm;
