import React from 'react';
import { EditView } from '@builtbypixel/nucleus';

import setup from './setup';
import {Flex, TabPanel, TabPanels, Tabs} from "@chakra-ui/react";
import Menu from "./Menu";
import CruiseLineDetails from "./components/CruiseLineDetails";
import OrderShips from "./components/OrderShips"
import Activities from "./components/Activities";
import FAQs from './components/FAQs';
import ReviewsTable from "../../components/reviewsTable";

const EditForm = () => {
  return (
    <EditView setup={setup} isFullWidth={false}>
        <Flex w='100%' minHeight='100vh' bg='white'>
            <Tabs flex={1} minHeight='100vh' isLazy>
                <Menu />
                <TabPanels>
                    <TabPanel>
                        <CruiseLineDetails />
                    </TabPanel>
                    <TabPanel>
                        <OrderShips />
                    </TabPanel>
                    <TabPanel>
                        <ReviewsTable />
                    </TabPanel>
                    <TabPanel>
                        <Activities />
                    </TabPanel>
                    <TabPanel>
                        <FAQs />
                    </TabPanel>
                </TabPanels>
            </Tabs>
        </Flex>
    </EditView>
  );
};

export default EditForm;
