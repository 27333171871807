// Example route/model.  Remove me!
import deals from './containers/Deals/routes';
import admins from './containers/AdminUsers/routes';
import users from './containers/Users/routes';
import bookings from './containers/Bookings/routes';
import excursions from './containers/Excursions/routes';
import callbacks from './containers/Enquiries/Callbacks/routes';
import holidayEnquiries from './containers/Enquiries/HolidayEnquiry/routes';
import itineraryBuilder from './containers/Enquiries/ItineraryBuilder/routes';
import dealEnquiries from './containers/Enquiries/DealEnquiry/routes';
import payments from './containers/Payments/routes';
import blogs from './containers/Blogs/routes';
import ships from './containers/Ships/routes';
import cruiseLines from './containers/CruiseLines/routes';
import pages from './containers/Pages/routes';
import referrers from './containers/Referrers/routes';
import banners from './containers/Banners/routes';
import countries from "./containers/Countries/routes";
import cities from './containers/Cities/routes';
import categories from './containers/Category/routes';
import subCategories from './containers/SubCategory/routes';
import cruiseLineDetails from './containers/CruiseLineDetails/routes';
import hotels from './containers/Hotels/routes';
import searchTags from './containers/SearchTags/routes';
import dealTypes from './containers/DealTypes/routes';
import activityCategories from "./containers/ActivityCategories/routes";
import seo from "./containers/Seo/routes";

const enquiries = [...callbacks, ...holidayEnquiries, ...itineraryBuilder, ...dealEnquiries];

const setup = [...ships, ...cruiseLines, ...pages, ...referrers, ...banners, ...countries, ...cities, ...categories, ...subCategories, ...cruiseLineDetails, ...hotels, ...searchTags, ...dealTypes, ...activityCategories];

const routes = [
    ...deals,
    ...admins,
    ...users,
    ...bookings,
    ...excursions,
    ...enquiries,
    ...payments,
    ...blogs,
    ...setup,
    ...seo
];

export default routes;
