const modelName = 'Search Tags';
const modelNameSingular = 'Search Tag';
const endpoint = '/search-tags';

const setup = {
  title: modelName,
  singular: modelNameSingular,
  model: modelName.replace(/\s+/g, '-').toLowerCase(),
  canDelete: true,
  canAddNew: true,
  canSearch: true,
  endpoint,
  accessor: 'id',
};

export default setup;
