import TravelLeg from "./TravelLeg";
import {useWatch} from "react-hook-form";
import CruiseLeg from "./CruiseLeg";
import DestinationLeg from "./DestinationLeg";
import FreeTextLeg from "./FreeTextLeg";
import GuideLeg from "./GuideLeg";
import DestinationWithTour from "./DestinationWithTour";
import {useMemo} from "react";
import {Box} from "@chakra-ui/react";
import SelectLeg from "./SelectLeg";

const TravelTypeOptions = [
    {
        id: 1,
        name: 'Plane',
    },
    {
        id: 2,
        name: 'Car',
    },
    {
        id: 3,
        name: 'Bus',
    },
    {
        id: 4,
        name: 'Train',
    },
    {
        id: 5,
        name: 'Boat',
    }
]

const getLeg = (legType) => {
    switch (legType) {
        case "Free Text":
            return FreeTextLeg;
        case "Destination":
            return DestinationLeg;
        case "Cruise":
            return CruiseLeg;
        case "Travel":
            return TravelLeg;
        case "Guide":
            return GuideLeg;
        case "Destination With Tour":
            return DestinationWithTour;
        default:
            return SelectLeg;
    }
}

const Legs = ({ parentName: name, destinationOptions, destinationTags, hotelOptions, boardTypes, searchTagOptions, shipOptions, ...props }) => {
    const LegType = useWatch({ name: `${name}.leg_type.name` })

    const Leg = useMemo(() => getLeg(LegType), [LegType]);

    return (
        <>
                        <Box as="span" flex='1' textAlign='left'>
                            {LegType} Leg
                        </Box>
                        <Leg
                            name={name}
                            travelTypeOptions={TravelTypeOptions}
                            destinationOptions={destinationOptions}
                            destinationTags={destinationTags}
                            hotelOptions={hotelOptions}
                            boardTypes={boardTypes}
                            searchTagOptions={searchTagOptions}
                            shipOptions={shipOptions}
                        />
        </>
    )
};

export default Legs;