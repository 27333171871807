import { Tab, TabList } from '@chakra-ui/react';
import React from 'react';

const EditMenu = () => {
  return (
    <TabList pt='10px' pl='10px' w='100%'>
      <Tab>Details</Tab>
      <Tab>Options</Tab>
      <Tab>Dates</Tab>
      <Tab>Reviews</Tab>
    </TabList>
  );
};

export default EditMenu;
