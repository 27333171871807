import React, {useEffect, useState} from 'react';
import { EditView, useHttp } from '@builtbypixel/nucleus';

import setup from './setup';
import {Container, Flex, TabPanel, TabPanels, Tabs} from "@chakra-ui/react";
import Menu from "./Menu";
import Details from './components/Details'
import OrderDestinations from './components/OrderDestinations'
import Activities from "./components/Activities";
import FAQs from "./components/FAQs";
import ReviewsTable from "../../components/reviewsTable";

const EditForm = () => {
    const Http = useHttp();
    const [regions, setRegions] = useState();
    const [mutliCenterRegions, setMultiCenterRegions] = useState();

    useEffect(() => {
        Http.get('/regions').then(res => setRegions(res.data.data));
        Http.get('/multi-center-regions').then(res => setMultiCenterRegions(res.data.data));
    }, [Http]);

  return (
    <EditView setup={setup} isFullWidth={false}>
      <Container
          p={{ base: '10px', sm: '15px' }}
          w='100%'
          variant='card'
          rounded='none'
          maxWidth='none'
      >
          <Flex w='100%' minHeight='100vh' bg='white'>
              <Tabs flex={1} minHeight='100vh' isLazy>
                  <Menu />
                  <TabPanels>
                      <TabPanel>
                          <Details regions={regions} multiCenterRegions={mutliCenterRegions} />
                      </TabPanel>
                      <TabPanel>
                          <OrderDestinations />
                      </TabPanel>
                      <TabPanel>
                          <ReviewsTable />
                      </TabPanel>
                      <TabPanel>
                          <Activities />
                      </TabPanel>
                      <TabPanel>
                          <FAQs />
                      </TabPanel>
                  </TabPanels>
              </Tabs>
          </Flex>
      </Container>
    </EditView>
  );
};

export default EditForm;
