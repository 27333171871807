import {Box, HStack, Radio} from "@chakra-ui/react";
import {useState} from "react";
import {FaStar} from "react-icons/fa";

const Stars = (props) => {
    const { onChange, value, count } =
        props;
    const [hover, setHover] = useState(null);
    return (
        <HStack spacing={0}>
            {[...Array(count || 5)].map((star, index) => {
                const ratingValue = index + 1;
                return (
                    <Box
                        as="label"
                        key={index}
                        color={ratingValue <= (hover || value) ? "#ffc107" : "#e4e5e9"}
                        onMouseEnter={() => setHover(ratingValue)}
                        onMouseLeave={() => setHover(null)}
                        pr="2"
                    >
                        <Radio
                            name="rating"
                            onChange={() => onChange(ratingValue)}
                            value={ratingValue}
                            display="none"
                        ></Radio>
                        <FaStar
                            cursor={"pointer"}
                            size={35}
                            transition="color 200ms"
                        />
                    </Box>
                );
            })}
        </HStack>
    )
}

export default Stars;