import React from 'react';
import { Route } from 'react-router-dom';

import Page from '../components/Page';

const PublicRoutes = ({ component: Component, title, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props => (
        <Page title={title} auth={false}>
          <Component {...props} />
        </Page>
      )}
    />
  );
};

export default PublicRoutes;
