import React from 'react';
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
  ChakraProvider,
  Button,
  Icon,
  Box,
  useDisclosure,
} from '@chakra-ui/react';
import { TiInfoOutline } from 'react-icons/ti';
import PropTypes from 'prop-types';

const PopConfirm = props => {
  const { children, title, onConfirm, okText, cancelText } = props;

  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = React.useRef();

  return (
    <ChakraProvider>
      <Box onClick={() => onOpen()}>{children}</Box>
      <AlertDialog
        motionPreset='slideInBottom'
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        isOpen={isOpen}
        isCentered
      >
        <AlertDialogOverlay />

        <AlertDialogContent>
          <AlertDialogHeader>
            {' '}
            <Icon fontSize={26} mr='10px' color='warning'>
              <TiInfoOutline />
            </Icon>{' '}
            Confirmation
          </AlertDialogHeader>
          <AlertDialogCloseButton />
          <AlertDialogBody>{title}</AlertDialogBody>
          <AlertDialogFooter>
            <Button ref={cancelRef} onClick={onClose}>
              {cancelText || 'Cancel'}
            </Button>
            <Button
              colorScheme='red'
              ml={3}
              onClick={() => {
                onConfirm();
                onClose();
              }}
            >
              {okText || 'Confirm'}
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </ChakraProvider>
  );
};

PopConfirm.propTypes = {
  /**
   * The message for the confirmation
   */
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  /**
   * The text for the OK button
   */
  okText: PropTypes.string,
  /**
   * The text for the Cancel button
   */
  cancelText: PropTypes.string,
  /**
   * The props or overriding styles for the OK button
   */
  okButtonProps: PropTypes.object,
  /**
   * The props or overriding styles for the Cancel button
   */
  cancelButtonProps: PropTypes.object,
  /**
   * The function to action on confirm
   */
  onConfirm: PropTypes.func,
};

PopConfirm.defaultProps = {
  okText: 'OK',
  cancelText: 'Cancel',
  title: 'Are you sure?',
};

export default PopConfirm;
