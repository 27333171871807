import React from 'react';
import { Flex, useColorMode, Image, IconButton } from '@chakra-ui/react';
import { RiMenuUnfoldLine } from 'react-icons/ri';
import { useSetRecoilState } from 'recoil';
import { BiExit } from 'react-icons/bi';

import { getSite } from '../../helpers';
import { menuAtom } from '../../state/global';
import { logout } from '../../helpers';

const MobileHeader = () => {
  const site = getSite;
  const { colorMode } = useColorMode();
  const setMenu = useSetRecoilState(menuAtom);

  return (
    <Flex
      align='center'
      justify='space-between'
      w='100%'
      h='50px'
      bg='white'
      borderBottom='1px'
      borderColor='gray.200'
      px='10px'
      position='relative'
      zIndex={1}
      display={{ base: 'flex', xl: 'none' }}
    >
      <IconButton variant='link' fontSize='20px' onClick={() => setMenu(true)}>
        <RiMenuUnfoldLine />
      </IconButton>
      <Image
        h='30px'
        objectFit='contain'
        src={site?.clientLogoUrl[colorMode]}
      />
      <IconButton
        rounded='full'
        size='lg'
        variant='ghost'
        onClick={() => logout()}
        mt='auto'
        icon={<BiExit fontSize={20} />}
      />
    </Flex>
  );
};

export default MobileHeader;
