import React from 'react';
import { Skeleton, Stack } from '@chakra-ui/react';

const TableLoader = () => {
  return (
    <React.Fragment>
      <Stack spacing='5px' isInline>
        <Skeleton width='100%' variant='rect' h='30px' mb='10px' />
        <Skeleton width='100%' variant='rect' h='30px' mb='10px' />
        <Skeleton width='100%' variant='rect' h='30px' mb='10px' />
        <Skeleton width='100%' variant='rect' h='30px' mb='10px' />
        <Skeleton width='100%' variant='rect' h='30px' mb='10px' />
      </Stack>

      <Stack spacing='5px' isInline mt='10px'>
        <Skeleton width='100%' variant='rect' h='10px' mb='5px' />
        <Skeleton width='100%' variant='rect' h='10px' mb='5px' />
        <Skeleton width='100%' variant='rect' h='10px' mb='5px' />
        <Skeleton width='100%' variant='rect' h='10px' mb='5px' />
        <Skeleton width='100%' variant='rect' h='10px' mb='5px' />
      </Stack>

      <Stack spacing='5px' isInline mt='10px'>
        <Skeleton width='100%' variant='rect' h='10px' mb='5px' />
        <Skeleton width='100%' variant='rect' h='10px' mb='5px' />
        <Skeleton width='100%' variant='rect' h='10px' mb='5px' />
        <Skeleton width='100%' variant='rect' h='10px' mb='5px' />
        <Skeleton width='100%' variant='rect' h='10px' mb='5px' />
      </Stack>
    </React.Fragment>
  );
};

export default TableLoader;
