const modelName = 'Itinerary Builder Enquiries';
const modelNameSingular = 'Itinerary Builder Enquiry';
const endpoint = '/itinerary-builder';

const setup = {
  title: modelName,
  singular: modelNameSingular,
  model: modelName.replace(/\s+/g, '-').toLowerCase(),
  canDelete: true,
  canAddNew: false,
  canSearch: true,
  canExport: "itinerary-builder/export",
  endpoint,
  accessor: 'id',
};

export default setup;
