import React from 'react';
import { Table } from '@builtbypixel/nucleus';
import { Box } from '@chakra-ui/react';

const PaymentDetails = () => {
  return (
    <>
      <br />
      <h2>Payment Details</h2>
      <br />

      <Box height='300px' mb='3'>
        <Table
          accessor='bookings'
          data={{ data: [] }}
          columns={[
            {
              Header: 'Booking Ref',
              accessor: 'id',
            },
            {
              Header: 'Due Date',
              accessor: 'due_date',
            },
            {
              Header: 'Price',
              accessor: 'price',
            },
            {
              Header: 'Status',
              accessor: 'status',
            },
          ]}
        />
      </Box>

      <br />
      <br />
    </>
  );
};

export default PaymentDetails;
