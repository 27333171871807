import React from 'react';
import { Field, EditView } from '@builtbypixel/nucleus';

import setup from './setup';
import {Box, Container, Divider} from "@chakra-ui/react";

const PlanItem = ({ parentName: name }) => {
  return (
      <Box display='flex' flexDir='row' gridGap='3'>
        <Field
          auto
          isFullWidth
          name={`${name}.dest`}
          component="text"
          label={`Destination `}
          disabled
        />
        <Field
            auto
            isFullWidth
            name={`${name}.nights`}
            component="text"
            label={`Nights `}
            disabled
        />
      </Box>
  )
}

const EditForm = () => {
  return (
    <EditView setup={setup} isFullWidth={false}>
      <Container
          p={{ base: '10px', sm: '15px' }}
          w='100%'
          variant='card'
          rounded='none'
          maxWidth='none'
      >
        <Box display='flex' flexDir='row' gridGap='3'>
          <Field
              auto
              isFullWidth
              name="ref"
              label="Referrer"
              component="text"
              disabled
          />
          <Field
              auto
              isFullWidth
              name="gclid"
              label="GCLID"
              component="text"
              disabled
          />
        </Box>

        <Divider mb="4" />

        <Box display='flex' flexDir='row' gridGap='3'>
          <Field
              auto
              isFullWidth
              name="enquiry_details.first_name"
              label="First Name"
              component="text"
              disabled
          />
          <Field
              auto
              isFullWidth
              name="enquiry_details.last_name"
              label="Last Name"
              component="text"
              disabled
          />
        </Box>

        <Box display='flex' flexDir='row' gridGap='3'>
          <Field
              auto
              isFullWidth
              name="enquiry_details.email"
              label="Email"
              component="text"
              disabled
          />
          <Field
              auto
              isFullWidth
              name="enquiry_details.phone_number"
              label="Phone Number"
              component="text"
              disabled
          />
        </Box>

        <Box display='flex' flexDir='row' gridGap='3'>
          <Field
              auto
              isFullWidth
              name="enquiry_details.date"
              label="Departure Date"
              dateFormat="ddd d MMM YYYY"
              component="date"
              disabled
          />
          <Field
              auto
              isFullWidth
              name="enquiry_details.persons"
              label="Travellers"
              component="number"
              disabled
          />
        </Box>

        <Box display='flex' flexDir='row' gridGap='3'>
          <Field
              auto
              isFullWidth
              name="enquiry_details.nights"
              label="Nights"
              component="number"
              disabled
          />
          <Field
              auto
              isFullWidth
              name="enquiry_details.other"
              label="Requirements"
              component="richtext"
              disabled
          />
        </Box>

        <Divider mb="4" />

        <h3>Enquiry Details</h3>

        <Field
          auto
          name="details.airport"
          component="text"
          label="Airport"
        />

        <Box display='flex' flexDir='row' gridGap='3'>
        <Field
          auto
          isFullWidth
          name="details.plan"
          component="repeater"
          layout="vertical"
          fieldArray={[{
            component: PlanItem
          }]}
        />
        </Box>
      </Container>
    </EditView>
  );
};

export default EditForm;
