import { Field } from "@builtbypixel/nucleus";

const SelectSection = ({ name }) => {
    return (
        <Field
            auto
            isFullWidth
            name={`${name}.section_type`}
            component="select"
            valueKey="value"
            labelKey="name"
            placeholder="Choose a Section type"
            options={[
                { name: "Paragraph", value: "Paragraph" },
                { name: "Image", value: "Image" },
                { name: "Image-Paragraph", value: "Image-Paragraph" },
                { name: "Paragraph-Image", value: "Paragraph-Image" },
                { name: "Instagram", value: "Instagram" }
            ]}
        />
    )
}

export default SelectSection