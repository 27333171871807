import React, {useEffect, useState} from 'react';
import { Field, EditView, useHttp } from '@builtbypixel/nucleus';

import setup from './setup';
import {Box, Container} from "@chakra-ui/react";

const EditForm = () => {
    const Http = useHttp();
    const [categories, setCategories] = useState();


    useEffect(() => {
        Http.get('/top-level-categories').then(res => setCategories(res.data.data));
    }, [Http])

  return (
    <EditView setup={setup} isFullWidth={false}>
      <Container
          p={{ base: '10px', sm: '15px' }}
          w='100%'
          variant='card'
          rounded='none'
          maxWidth='none'
      >
        <Box display='flex' flexDir='row' gridGap='3'>
            <Field auto isFullWidth name="parent" component="select" label="Parent Category" options={categories} labelKey="name" valueKey="id" />
        </Box>
          <Field auto isFullWidth name="name" component="text" label="Name" />
          <Field auto isFullWidth name="sub_title" component="text" label="Sub Title" />
          <Field auto isFullWidth name="summary" component="richtext" label="Summary" />
          <Field auto isFullWidth name="description" component="richtext" label="Description" />
          <Field auto isFullWidth name="banner_image" component="file" label="Hero Image" />
      </Container>
    </EditView>
  );
};

export default EditForm;
