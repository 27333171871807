import React from 'react';
import { Switch } from 'react-router-dom';

import routes from './routes';
import PrivateRoute from './Private';
import PublicRoute from './Public';

const Routes = ({ clientRoutes }) => {
  return (
    <Switch>
      {clientRoutes &&
        clientRoutes.map((route, index) => {
          if (route.auth) {
            return <PrivateRoute key={`route-${index}`} {...route} />;
          }
          return <PublicRoute key={`route-${index}`} {...route} />;
        })}
      {routes.map((route, index) => {
        if (route.auth) {
          return <PrivateRoute key={`route-${index}`} {...route} />;
        }
        return <PublicRoute key={`route-${index}`} {...route} />;
      })}
    </Switch>
  );
};

export default Routes;
