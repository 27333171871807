import React from 'react';
import { Field, EditView } from '@builtbypixel/nucleus';

import setup from './setup';
import {Box, Container} from "@chakra-ui/react";

const FieldRenderer = ({ name, value }) => {
    const fieldNames = Object.keys(value);

    return fieldNames.map((field) => {
        const fieldType = field.split('_Type_')[1];
        const fieldName = field.split('_Type_')[0].replace(/_/g, ' ')

        switch (fieldType) {
            case "TextInput":
                return <Field
                    auto
                    isFullWidth
                    component="text"
                    label={fieldName}
                    name={`${name}.${field}`}
                />
            case "Wysiwyg":
                return <Field
                    auto
                    isFullWidth
                    component="richtext"
                    label={fieldName}
                    name={`${name}.${field}`}
                />
            case "FileUpload":
                return <Field
                    auto
                    isFullWidth
                    component="file"
                    label={fieldName}
                    name={`${name}.${field}`}
                />
            case "Section":
                return (
                    <section>
                        <hr style={{ marginTop: 30, marginBottom: 30 }} />
                    </section>
                )
            default:
                return null;
        }
    })
}

const EditForm = () => {
  return (
    <EditView setup={setup} isFullWidth={false}>
      <Container
          p={{ base: '10px', sm: '15px' }}
          w='100%'
          variant='card'
          rounded='none'
          maxWidth='none'
      >
        <Box display='flex' flexDir='row' gridGap='3'>
            <Field
                auto
                isFullWidth
                name="name"
                component="text"
                label="Page Name"
                disabled
            />
            <Field
                auto
                isFullWidth
                name="url"
                component="text"
                label="Page URL"
                disabled
            />
        </Box>

          <Field
              auto
              isFullWidth
              name="content"
              component={FieldRenderer}
          />
      </Container>
    </EditView>
  );
};

export default EditForm;
