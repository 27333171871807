import React from 'react';
import { Field, EditView } from '@builtbypixel/nucleus';

import setup from './setup';
import {Box, Container} from "@chakra-ui/react";

const EditForm = () => {
  return (
    <EditView setup={setup} isFullWidth={false}>
      <Container
          p={{ base: '10px', sm: '15px' }}
          w='100%'
          variant='card'
          rounded='none'
          maxWidth='none'
      >
        <Box display='flex' flexDir='row' gridGap='3'>
            <Field auto isFullWidth name="name" component="text" label="Name" />
        </Box>
      </Container>
    </EditView>
  );
};

export default EditForm;
