import React, {useEffect, useState} from 'react';
import { Field, EditView, useHttp } from '@builtbypixel/nucleus';

import setup from './setup';
import {Box, Container} from "@chakra-ui/react";

const EditForm = () => {
  const Http = useHttp()
  const [destinationOptions, setDestinationOptions] = useState();

  useEffect(() => {
    Http.get('/destinations').then(res => setDestinationOptions(res.data.data));
  }, [Http]);

  return (
    <EditView setup={setup} isFullWidth={false}>
      <Container
          p={{ base: '10px', sm: '15px' }}
          w='100%'
          variant='card'
          rounded='none'
          maxWidth='none'
          flex="1"
      >
      <Box display='flex' flexDir='row' gridGap='3'>
        <Field
            auto
            isFullWidth
          name="name"
          component="text"
          label="Name"
        />
        <Field
            auto
            isFullWidth
          name="destination"
          component="select"
          label="Destination"
          options={destinationOptions}
          labelKey="name"
          valueKey="id"
        />
      </Box>
        <Box display='flex' flexDir='row' gridGap='3' flex="0.5">
          <Field
              name="price"
              component="text"
              label="Price (£)"
              isRequired
              width="50%"
          />
        </Box>

        <Field
            auto
            isFullWidth
            name="image"
            component="file"
            label="Image"
        />

        <Field
            auto
            isFullWidth
            name="summary"
            component="richtext"
            label="Summary"
        />
      </Container>
    </EditView>
  );
};

export default EditForm;
