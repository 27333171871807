import { Field } from "@builtbypixel/nucleus";
import {Box} from "@chakra-ui/react";

const TravelLeg = ({ name, travelTypeOptions }) => {
    return (
        <>
            <Box display='flex' flexDir='row' gridGap='3'>
            <Field
                auto
                isFullWidth
                component="text"
                name={`${name}.leg_detail.name`}
                label="Title"
            />

            <Field
                auto
                isFullWidth
                component="select"
                name={`${name}.leg_detail.travel_type`}
                label="Travel Type"
                options={travelTypeOptions}
                labelKey="name"
                valueKey="id"
            />
            </Box>
        </>
    )
};

export default TravelLeg;