import {Box} from "@chakra-ui/react";
import React, {useEffect, useState} from "react";
import { Field, useHttp } from "@builtbypixel/nucleus";

const Details = () => {
    const Http = useHttp();
    const [countries, setCountries] = useState();

    useEffect(() => {
        Http.get('/countries').then(res => setCountries(res.data.data));
    }, [Http])

    return (
        <>
            <Box display='flex' flexDir='row' gridGap='3'>
                <Field
                    auto
                    isFullWidth
                    name="name"
                    component="places"
                    isRequired
                    label="City Name"
                />
                <Field
                    auto
                    isFullWidth
                    name="countries"
                    component="select"
                    label="Country"
                    labelKey="value"
                    valueKey="id"
                    options={countries}
                />
            </Box>
            <Field
                auto
                isFullWidth
                name="video"
                component="text"
                label="Video URL"
            />
            <Field
                auto
                isFullWidth
                name="subtitle"
                component="text"
                label="Sub Title"
            />
            <Field
                auto
                isFullWidth
                name="banner_image"
                component="file"
                label="Hero Image"
            />
            <Field
                auto
                isFullWidth
                name="summary"
                component="richtext"
                label="Top Description (Summary)"
            />
            <Field
                auto
                isFullWidth
                name="description"
                component="richtext"
                label="Description"
            />
        </>
    )
}

export default Details;