import {
  Stack,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  useDisclosure,
  Button,
  Input,
  Flex,
  Text,
} from '@chakra-ui/react';
import React, { useCallback } from 'react';
import { useRecoilState } from 'recoil';
import { HiOutlineFilter } from 'react-icons/hi';

import Select from '../../Fields/Select';
import Datepicker from '../../Fields/DatePicker';
import { tableAtom } from '../../state/global';

const Filters = ({ filters }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [tableState, setTableState] = useRecoilState(tableAtom);

  const setFilter = useCallback(
    (name, value) => {
      const _filters = { ...tableState.params.filters };
      _filters[name] = value;
      setTableState(old => ({
        ...old,
        params: {
          ...old.params,
          filters: _filters || null,
        },
      }));
    },
    [setTableState, tableState.params.filters]
  );

  const getField = field => {
    switch (field.component) {
      case 'date':
        return (
          <Flex w='100%' direction='column'>
            <Text fontWeight='bold' mb='5px'>
              {field.label}
            </Text>
            <Datepicker
              {...field}
              defaultValue={field.defaultValue}
              value={
                tableState.params?.filters
                  ? tableState.params?.filters[field.name]
                  : null
              }
              onChange={date => setFilter(field.name, date)}
            />
          </Flex>
        );
      case 'select':
        return (
          <Flex w='100%' direction='column'>
            <Text fontWeight='bold' mb='5px'>
              {field.label}
            </Text>
            <Select
              value={
                tableState.params?.filters
                  ? tableState.params?.filters[field.name]
                  : null
              }
              defaultValue={field.defaultValue}
              onChange={value => setFilter(field.name, value)}
              options={field.options}
              {...field}
            ></Select>
          </Flex>
        );
      case 'text':
        return (
          <Flex w='100%' direction='column'>
            <Text fontWeight='bold' mb='5px'>
              {field.label}
            </Text>
            <Input
              {...field}
              defaultValue={field.defaultValue}
              value={
                tableState.params?.filters
                  ? tableState.params?.filters[field.name]
                  : null
              }
              onChange={e => setFilter(field.name, e.target.value)}
            />
          </Flex>
        );
      default:
        return null;
    }
  };

  return (
    <>
      <Button
        variant='ghost'
        rightIcon={<HiOutlineFilter />}
        colorScheme='blue'
        onClick={onOpen}
      >
        Apply Filters
      </Button>
      <Drawer isOpen={isOpen} placement='right' onClose={onClose} size='md'>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>Apply Filters</DrawerHeader>

          <DrawerBody>
            <Stack spacing='10px' w='100%'>
              {filters.map((filter, i) => (
                <Flex key={`filter-${i}`} w='100%' align='center'>
                  {getField(filter)}
                </Flex>
              ))}
            </Stack>
          </DrawerBody>

          <DrawerFooter>
            <Button variant='outline' mr={3} onClick={onClose}>
              Cancel
            </Button>
            <Button colorScheme='blue' onClick={() => onClose()}>
              OK
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  );
};

export default Filters;
