import React from 'react';
import { LinkButton, ListWrapper } from '@builtbypixel/nucleus';
import { Button } from '@chakra-ui/react';

import setup from './setup';

const extraMenu = () => null;

const List = () => {
  const columns = [
    {
      Header: 'Ref',
      accessor: 'id',
      id: 'edit',
      Cell: ({ row, value }) => (
          <LinkButton to={`${setup.model}/edit/${row.original[setup.accessor]}`}>
            <Button variant='link' variantColor='secondary' rounded='md'>
              {value}
            </Button>
          </LinkButton>
      ),
    },
    {
      Header: 'First Name',
      accessor: 'first_name',
    },
    {
      Header: 'Last Name',
      accessor: 'last_name'
    },
    {
      Header: 'Price',
      accessor: 'price',
      Cell: ({ value }) => new Intl.NumberFormat('en-GB', { style: 'currency', currency: 'GBP' }).format(value)
    },
    {
      Header: 'Booking Reference',
      accessor: 'booking_id'
    }
  ];

  return <ListWrapper setup={setup} columns={columns} extraMenu={extraMenu} />;
};

export default List;
