import { Box } from '@chakra-ui/react';
import { Field } from '@builtbypixel/nucleus';
import React from 'react';

const TravellerInfo = () => {
  return (
    <>
      <br />
      <h2>Lead Traveller Details</h2>
      <br />

      <Box display='flex' flexDir='row' gridGap='3'>
        <Field
          auto
          isFullWidth
          name='user.first_name'
          component='text'
          label='First Name'
        />
        <Field
          auto
          isFullWidth
          name='user.last_name'
          component='text'
          label='Last Name'
        />
      </Box>

      <Box display='flex' flexDir='row' gridGap='3'>
        <Field
          auto
          isFullWidth
          name='user.email'
          component='text'
          label='Email'
        />
        <Field
          auto
          isFullWidth
          name='user.phone_number'
          component='text'
          label='Phone number'
        />
      </Box>

      <Box display='flex' flexDir='row' gridGap='3'>
        <Field
          auto
          isFullWidth
          name='date_of_birth'
          component='date'
          label='Date of Birth'
        />
        <Field
          name='gender'
          component='select'
          label='Gender'
          labelKey='value'
          valueKey='id'
          options={[
            {
              value: 'Male',
              id: 1,
            },
            {
              value: 'Female',
              id: 2,
            },
            {
              value: 'Other',
              id: 3,
            },
          ]}
        />
      </Box>

      <Box display='flex' flexDir='row' gridGap='3'>
        <Field
          auto
          isFullWidth
          name='address_line_1'
          component='text'
          label='Address line 1'
        />
        <Field
          auto
          isFullWidth
          name='address_line_2'
          component='text'
          label='Address line 2'
        />
      </Box>
      <Box display='flex' flexDir='row' gridGap='3'>
        <Field
          auto
          isFullWidth
          name='postcode'
          component='text'
          label='Postcode'
        />
        <Field auto isFullWidth name='city' component='text' label='City' />
      </Box>
      <Field auto name='country' component='text' label='Country' />

      <br />
      <h2>Additional Traveller Details</h2>
      <br />

      <Field
        component='repeater'
        name='additional_passengers'
        singleName='Passenger'
        layout='vertical'
        fieldArray={[
          {
            name: 'first_name',
            component: 'text',
            label: 'First Name',
          },
          {
            name: 'last_name',
            component: 'text',
            label: 'Last Name',
          },
          {
            name: 'gender',
            component: 'select',
            label: 'Gender',
            labelKey: 'value',
            valueKey: 'id',
            options: [
              {
                value: 'Male',
                id: 1,
              },
              {
                value: 'Female',
                id: 2,
              },
              {
                value: 'Other',
                id: 3,
              },
            ],
          },
          {
            name: 'date',
            component: 'number',
            label: 'Date',
          },
          {
            name: 'month',
            component: 'number',
            label: 'Month',
          },
          {
            name: 'year',
            component: 'number',
            label: 'Year',
          },
        ]}
      />
    </>
  );
};

export default TravellerInfo;
