import React from 'react';
import { LinkButton, ListWrapper } from '@builtbypixel/nucleus';
import { Button } from '@chakra-ui/react';
import moment from 'moment';

import setup from './setup';

const extraMenu = () => null;

const List = () => {
  const columns = [
    {
      Header: 'Title',
      accessor: 'title',
      id: 'edit',
      Cell: ({ row, value }) => (
          <LinkButton to={`${setup.model}/edit/${row.original[setup.accessor]}`}>
            <Button variant='link' variantColor='secondary' rounded='md'>
              {value}
            </Button>
          </LinkButton>
      ),
    },
    {
      Header: 'Linked Destinations',
      accessor: 'destinations',
      Cell: ({ value }) => (
          <ul>
            {value.map((item, i) => (
                <li>{item.name}</li>
            ))}
          </ul>
      )
    },
      {
          Header: "Created",
          accessor: 'created_at.date',
          Cell: ({ value }) => moment(value).format("LLL")
      }
  ];

  return <ListWrapper setup={setup} columns={columns} extraMenu={extraMenu} />;
};

export default List;
