import React from "react";
import { Field } from "@builtbypixel/nucleus";

const OrderShips = () => {
    return (
        <Field
            name="ships"
            component="order"
            labelKey="name"
        />
    )
}

export default OrderShips;