import React from 'react';
import { LinkButton, ListWrapper } from '@builtbypixel/nucleus';
import { Button } from '@chakra-ui/react';

import setup from './setup';
import moment from "moment";

const extraMenu = () => null;

const List = () => {
  const columns = [
    {
      Header: 'Name',
      accessor: 'name',
      id: 'edit',
      Cell: ({ row, value }) => (
          <LinkButton to={`${setup.model}/edit/${row.original[setup.accessor]}`}>
            <Button variant='link' variantColor='secondary' rounded='md'>
              {value}
            </Button>
          </LinkButton>
      ),
    },
    {
      Header: 'Created',
      accessor: 'created_at',
      Cell: ({ value }) => moment(value).format('LLL')
    },
  ];

  return <ListWrapper setup={setup} columns={columns} extraMenu={extraMenu} />;
};

export default List;
