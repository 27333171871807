import { atom } from 'recoil';

import config from '../../config.json';

export const globalAtom = atom({
  key: 'globalState',
  default: {
    config: config,
    routes: null,
    links: [],
    user: null,
    authLoading: true,
    formErrors: null,
  },
});

export const menuAtom = atom({
  key: 'menuState',
  default: false,
});

export const tableAtom = atom({
  key: 'tableState',
  default: {
    selected: [],
    selectedRows: [],
    params: {
      filters: [],
      search: null,
      pagination: 20,
      page: 1,
      sort: null,
    },
  },
});
