import {Box} from "@chakra-ui/react";
import React from "react";
import { Field } from "@builtbypixel/nucleus";

const Details = ({ regions, multiCenterRegions }) => {
    return (
        <>
            <Box display='flex' flexDir='row' gridGap='3'>
                <Field
                    auto
                    isFullWidth
                    name="name"
                    component="places"
                    isRequired
                    label="Country Name"
                />
                <Field
                    auto
                    isFullWidth
                    name="subtitle"
                    component="text"
                    label="Sub Title"
                />
            </Box>
            <Field
                auto
                isFullWidth
                name="summary"
                component="richtext"
                label="Top Description (Summary)"
            />
            <Field
                auto
                isFullWidth
                name="multi_center_summary"
                component="richtext"
                label="Top Description Multi Centre (Summary)"
            />
            <Field
                auto
                isFullWidth
                name="description"
                component="richtext"
                label="Description"
            />
            <Field
                auto
                isFullWidth
                name="multi_center_description"
                component="richtext"
                label="Description Multi Centre"
            />
            <Field
                auto
                isFullWidth
                name="banner_image"
                component="file"
                label="Hero Image"
            />
            <Box display='flex' flexDir='row' gridGap='3'>
                <Field
                    auto
                    isFullWidth
                    name="region"
                    component="select"
                    isRequired
                    label="Region"
                    options={regions}
                    labelKey="name"
                    valueKey="id"
                />
                <Field
                    auto
                    isFullWidth
                    name="multi_center_region"
                    component="select"
                    label="Mutli Centre Region"
                    options={multiCenterRegions}
                    labelKey="name"
                    valueKey="id"
                />
            </Box>
            <Box display='flex' flexDir='row' gridGap='3'>
                <Field
                    auto
                    isFullWidth
                    name="video_link"
                    component="text"
                    isRequired
                    label="Video Link"
                />
                <Field
                    auto
                    isFullWidth
                    name="iso_code"
                    component="text"
                    label="ISO Code"
                />
            </Box>
        </>
    )
}

export default Details;