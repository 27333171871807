import React from 'react';
import { BiAtom, BiExit } from 'react-icons/bi';
import { FiUsers } from 'react-icons/fi';
import {
  Box,
  Text,
  Button,
  Flex,
  Icon,
  IconButton,
  Tooltip,
  Stack,
  useColorModeValue,
  useToast,
} from '@chakra-ui/react';
import { useRecoilValue } from 'recoil';
import { useHistory } from 'react-router-dom';
import { use100vh } from 'react-div-100vh';
import { RiEraserLine } from 'react-icons/ri';

import { globalAtom, menuAtom } from '../../state/global';
import { logout } from '../../helpers';
import { useHttp } from '../../hooks';

import SiteModal from './Modal';

const SideBar = () => {
  const globalState = useRecoilValue(globalAtom);
  const menuState = useRecoilValue(menuAtom);
  const Http = useHttp();
  const toast = useToast();
  const toastId = 1;

  const history = useHistory();
  const height = use100vh();

  const borderColor = useColorModeValue('gray.200', 'gray.700');
  const bg = useColorModeValue('white', 'gray.900');
  const icon = useColorModeValue('primary', 'white');

  // clearCache = () => {
  //   Http.get(`${this.props.site === 'gold' ? 'gold' : 'parfetts'}/clear-cloudflare`)
  //     .then(res => {
  //       this.setState({
  //         cache: 'success',
  //       });
  //     })
  //     .catch(err => {
  //       this.setState({ cache: 'failed' });
  //     });
  // };
  const areYouSure = () => {
    toast({
      id: toastId,
      title: 'Clear Cloudflare Cache?',
      description: (
        <Box>
          <Text mb='5px'>
            are you sure you want to clear all the data in the cache?
          </Text>
          <Flex justifyContent='space-evenly'>
            <Button color='black' onClick={() => clearCache()}>
              Yes
            </Button>
          </Flex>
        </Box>
      ),
      status: 'warning',
      isClosable: true,
    });
  };

  const clearCache = () => {
    Http.get(`/clear-cloudflare`)
      .then(res => {
        toast({
          title: 'Success',
          status: 'success',
          description: 'cloudflare cache cleared',
          isClosable: true,
        });
      })
      .catch(err => {
        toast({
          title: 'Error',
          description: 'There has been a problem with your request',
          status: 'error',
        });
      });
  };
  return (
    <Flex
      h={height}
      w='60px'
      direction='column'
      justify='space-between'
      align='center'
      pt='15px'
      pb='5px'
      px='8px'
      bg={bg}
      zIndex={1002}
      position={{ xs: 'fixed', md: 'relative' }}
      left={0}
      top={0}
      transform={{
        xs: menuState ? 'translateX(0%)' : 'translateX(-100%)',
        md: 'none',
      }}
      transition='0.3s all ease-in-out'
      borderRight='1px'
      borderColor={borderColor}
      display={{ base: 'none', xl: 'flex' }}
    >
      <Flex mb={20}>
        <Icon fontSize='26px' color={icon}>
          <BiAtom />
        </Icon>
      </Flex>

      <Stack spacing='10px' flex={1} justifyContent='flex-end'>
        {/* <IconButton rounded='full' size='lg' variant='ghost'>
               <Tooltip title='Content Manager' placement='right'>
                  <FiLayers fontSize={20} />
               </Tooltip>
            </IconButton> */}
        <Tooltip label='Clear cloudflare cache' placement='right'>
          <IconButton
            size='lg'
            variant='ghost'
            rounded='full'
            onClick={() => {
              if (!toast.isActive(toastId)) areYouSure();
            }}
          >
            <RiEraserLine />
          </IconButton>
        </Tooltip>
        <Tooltip label='Admin users' placement='right'>
          <IconButton
            rounded='full'
            size='lg'
            variant='ghost'
            onClick={() => history.push('/administrators')}
          >
            <FiUsers fontSize={20} />
          </IconButton>
        </Tooltip>
        {globalState.config?.siteSwitcher.show && <SiteModal />}

        {/* <Tooltip
               label={colorMode === 'light' ? 'Dark Mode' : 'Light Mode'}
               placement='right'
            >
               <IconButton
                  rounded='full'
                  size='lg'
                  variant='ghost'
                  onClick={() => toggleColorMode()}
                  mt='auto'
                  icon={<FiSun fontSize={20} />}
               />
            </Tooltip> */}

        <Tooltip label='Logout' placement='right'>
          <IconButton
            rounded='full'
            size='lg'
            variant='ghost'
            onClick={() => logout()}
            mt='auto'
            icon={<BiExit fontSize={20} />}
          />
        </Tooltip>
      </Stack>
    </Flex>
  );
};

export default SideBar;
