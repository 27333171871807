import { Field } from '@builtbypixel/nucleus';

const Paragraph = ({ name }) => {
    return (
        <Field
            auto
            isFullWidth
            name={`${name}.content`}
            component="richtext"
        />
    )
};

export default Paragraph;