import React from 'react';
import { Field } from '@builtbypixel/nucleus';

const renderExcursions = () => {
  return (
    <div>
      <Field
        component='repeater'
        name='excursions'
        singleName='Excursion'
        layout='vertical'
        fieldArray={[
          {
            name: 'excursion_title',
            component: 'text',
            label: 'Title',
          },
          {
            name: 'excursion_price',
            component: 'text',
            label: 'Price',
          },
          {
            name: 'excursion_summary',
            component: 'richtext',
            label: 'Summary',
          },
          {
            name: 'excursion_image',
            component: 'file',
            label: 'Image',
          },
        ]}
      />
    </div>
  );
};

const ExcursionInfo = () => {
  return (
    <>
      <br />
      <h2>Excursion Details</h2>
      <br />

      {renderExcursions()}
    </>
  );
};

export default ExcursionInfo;
