import React, { Suspense } from 'react';
import { RecoilRoot } from 'recoil';
import { CloudinaryContext } from 'cloudinary-react';
import { ChakraProvider, Spinner } from '@chakra-ui/react';
import { createContext } from 'react';

import theme from '../theme';

import Kernel from './Kernel';

import 'froala-editor/js/froala_editor.pkgd.min.js';
import 'froala-editor/js/plugins/align.min.js';
import 'froala-editor/js/plugins/link.min.js';
import 'froala-editor/js/plugins/lists.min.js';
import 'froala-editor/js/plugins/table.min.js';
import 'froala-editor/js/plugins/image.min.js';
import 'froala-editor/js/plugins/font_size.min.js';
import 'froala-editor/js/plugins/video.min.js';
import 'froala-editor/js/plugins/char_counter.min.js';

export const NucleusContext = createContext();

const NucleusCore = props => {
  const { routes, config, links } = props;

  return (
    <NucleusContext.Provider value={{ routes, config, links }}>
      <RecoilRoot>
        <CloudinaryContext
          cloudName={process.env.REACT_APP_CLOUDINARY_CLOUDNAME}
        >
          <Suspense fallback={<Spinner />}>
            <ChakraProvider theme={theme}>
              <Kernel />
            </ChakraProvider>
          </Suspense>
        </CloudinaryContext>
      </RecoilRoot>
    </NucleusContext.Provider>
  );
};

export default NucleusCore;
