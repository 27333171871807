import { Field } from "@builtbypixel/nucleus";

const SelectLeg = ({ name }) => {
    return (
        <>
            <Field
                auto
                isFullWidth
                name={`${name}.leg_type`}
                component="select"
                valueKey="value"
                labelKey="name"
                placeholder="Choose a leg type"
                options={[
                    { name: 'Destination', value: 'Destination' },
                    { name: 'Travel', value: 'Travel' },
                    { name: 'Guide', value: 'Guide' },
                    { name: 'Cruise', value: 'Cruise' },
                    {
                        name: 'Destination With Tour',
                        value: 'Destination With Tour',
                    },
                    {
                        name: 'Free Text',
                        value: 'Free Text',
                    },

                ]}
            />
        </>
    )
}

export default SelectLeg;