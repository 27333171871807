import { Field } from '@builtbypixel/nucleus';

const Image = ({ name }) => {
    return (
        <Field
            auto
            isFullWidth
            name={`${name}.image`}
            component="file"
        />
    )
}

export default Image;