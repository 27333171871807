import React, { useState } from 'react';
import {
  Flex,
  Container,
  Text,
  Box,
  Button,
  useColorMode,
  useToast,
  Image,
  Alert,
} from '@chakra-ui/react';
import { useFormContext } from 'react-hook-form';
import { useLocation } from 'react-router-dom';

import LinkButton from '../../../components/LinkButton';
import { Form, Field } from '../../../components/Form';
import { getSite } from '../../../helpers';
import { useHttp } from '../../../hooks';

const SubmitButton = ({ loading }) => {
  const { submit } = useFormContext();
  return (
    <Button
      size='lg'
      isFullWidth
      variant='primary'
      mb={3}
      type='submit'
      isLoading={loading}
      onClick={() => submit()}
      loadingText='Loading...'
    >
      Reset Password
    </Button>
  );
};

const ForgotPassword = () => {
  const { colorMode } = useColorMode();
  const toast = useToast();
  const Http = useHttp(false);
  const location = useLocation();

  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const queryString = require('query-string');
  const query = queryString.parse(location.search);

  const onSubmit = values => {
    setLoading(true);
    Http.post('/auth/reset-password', {
      data: { token: query.token, ...values },
    })
      .then(res => {
        setLoading(false);
        setSuccess(true);
      })
      .catch(() => {
        toast({
          title: 'Error',
          message: 'There was an error with your request',
          status: 'error',
          position: 'top',
          variant: 'plain',
        });
        setLoading(false);
      });
  };

  return (
    <Flex
      align='center'
      justify='center'
      h='100vh'
      w='100vw'
      direction='column'
    >
      <Container
        variant='Container'
        w='100%'
        maxWidth={500}
        rounded='20px'
        py='30px'
        boxShadow='md'
      >
        <Flex px={10} align='center' justify='center' w='100%' mb='20px'>
          <Image
            src={getSite?.clientLogoUrl[colorMode]}
            objectFit='contain'
            size='170px'
            maxHeight='100px'
          />
        </Flex>
        <Flex direction='column' align='center' justify='center' w='100%'>
          <Text as='h1' fontSize='26px' fontWeight='bold'>
            Password Reset
          </Text>

          <Box py={10} px={10} w='100%'>
            <Form onSubmit={onSubmit}>
              <Field
                name='password'
                component='text'
                variant='filled'
                size='lg'
                placeholder='New Password'
                label='Password'
                type='password'
                autoFocus
                mb={3}
                auto={false}
                rules={{ required: true }}
              />

              <Field
                name='password_confirmation'
                component='text'
                variant='filled'
                size='lg'
                label='Confirm Password'
                placeholder='Confirm Password'
                type='password'
                mb={3}
                auto={false}
                rules={{ required: true }}
              />

              <SubmitButton loading={loading} />
            </Form>
            <LinkButton to='/'>
              <Button variant='ghost' isFullWidth size='lg'>
                Return to Login
              </Button>
            </LinkButton>
          </Box>

          {success && (
            <Box p='20px'>
              <Alert status='success' variant='solid'>
                Your password has been reset
              </Alert>
            </Box>
          )}
        </Flex>
      </Container>
    </Flex>
  );
};
export default ForgotPassword;
