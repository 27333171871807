import {Field} from "@builtbypixel/nucleus";

const FreeTextLeg = ({ name }) => {
    return (
        <>
            <Field
                auto
                isFullWidth
                component="text"
                name={`${name}.leg_detail.name`}
                label="Title"
            />

            <Field
                auto
                isFullWidth
                component="richtext"
                name={`${name}.leg_detail.summary`}
                label="Summary"
            />
        </>
    )
};

export default FreeTextLeg;