import React from "react";
import { Field } from "@builtbypixel/nucleus";

const DestinationGuide = () => {
    return (
        <Field
            name="guide"
            component="richtext"
            label="Guide"
        />
    )
}

export default DestinationGuide;