import {Tab, TabList} from "@chakra-ui/react";
import React from "react";

const Menu = () => {
    return (
        <TabList pt='10px' pl='10px' w='100%'>
            <Tab>Cruise Line Details</Tab>
            <Tab>Order Ships</Tab>
            <Tab>Reviews</Tab>
            <Tab>Activities</Tab>
            <Tab>F.A.Q's</Tab>
        </TabList>
    );
}

export default Menu;