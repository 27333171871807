import {Table} from "../../Nucleus";
import {useWatch} from "react-hook-form";
import setup from "../../containers/Deals/setup";
import {Button,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
} from '@chakra-ui/react'
import React, {useState} from "react";

const ReviewsTable = () => {
    const [review, setReview] = useState(null);
    const reviews = useWatch({
        name: "reviews"
    })

    const columns = [
        {
            Header: 'Review ID',
            id: 'edit',
            accessor: 'id',
        },
        {
            Header: 'Customer',
            accessor: 'customer',
        },
        {
            Header: "Title",
            accessor: "title"
        },
        {
            Header: '',
            accessor: 'id',
            Cell: ({ row }) => <Button onClick={() => setReview({
                title: row.original.title,
                content: row.original.review
            })}>View Review</Button>
        }
    ];

    return (
        <>
            <Modal size={"5xl"} isOpen={review !== null} onClose={() => setReview(null)}>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>{review?.title}</ModalHeader>
                    <ModalCloseButton />
                <ModalBody>
                    {review?.content}
                </ModalBody>
                </ModalContent>
            </Modal>
        <Table data={{data: reviews}} columns={columns} />
        </>
    )
}

export default ReviewsTable;