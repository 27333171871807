import React from 'react';
import { Input } from '@chakra-ui/react';
import InputMask from 'react-input-mask';

const Mask = props => {
  const { onChange, value, children, ...rest } = props;
  return (
    <InputMask onChange={v => onChange(v)} value={value} {...rest}>
      {inputProps => <Input bg='white' {...inputProps} type='tel' />}
    </InputMask>
  );
};

export default Mask;
