import { extendTheme } from '@chakra-ui/react';

/// Palette
import colors from './colors';

/// Components
import Button from './components/button';
import Input from './components/input';
import Container from './components/container';
import Textarea from './components/textarea';
import Select from './components/select';
import NumberInput from './components/number';

const overrides = {
  styles: {
    global: ({ colorMode }) => ({
      'html, body': {
        fontSize: '14px',
        bg: colorMode === 'light' ? 'white' : 'gray.900',
        maxWidth: '100vw',
        overflowX: 'hidden',
      },
    }),
  },
  colors,

  components: {
    Input,
    Button,
    Container,
    Textarea,
    Select,
    NumberInput,
  },
  config: {
    cssVarPrefix: 'nucleus',
  },
};
export default extendTheme(overrides);
