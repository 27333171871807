import React, {useEffect, useState} from 'react';
import { Field, EditView, useHttp } from '@builtbypixel/nucleus';

import setup from './setup';
import {Box, Container} from "@chakra-ui/react";

const EditForm = () => {
    const Http = useHttp();
    const [rooms, setRooms] = useState();
    const [boards, setBoards] = useState();
    const [facilities, setFacilities] = useState();

    useEffect(() => {
        Http.get('/rooms').then(res => setRooms(res.data.data));
        Http.get('/board-types').then(res => setBoards(res.data.data));
        Http.get('/facilities').then(res => setFacilities(res.data.data));
    }, [Http]);

  return (
    <EditView setup={setup} isFullWidth={false}>
      <Container
          p={{ base: '10px', sm: '15px' }}
          w='100%'
          variant='card'
          rounded='none'
          maxWidth='none'
      >
        <Box display='flex' flexDir='row' gridGap='3'>
            <Field auto isFullWidth name="name" component="text" label="Name" />
            <Field auto isFullWidth name="star_rating" component="stars" label="Rating" />
        </Box>

          <Field auto isFullWidth name="trip_advisor_id" component="text" label="Trip Advisor ID" />
          <Field auto isFullWidth name="summary" component="richtext" label="Summary" />
          <Field auto isFullWidth name="images" component="file" label="Images" max={10} />

          <Box display='flex' flexDir='row' gridGap='3'>
              <Field auto isFullWidth name="rooms" component="select" label="Room Types" labelKey="name" valueKey="id" options={rooms} isMulti isHorizontal />
              <Field auto isFullWidth name="board_types" component="select" label="Board Types" labelKey="name" valueKey="id" options={boards} isMulti isHorizontal />
          </Box>

          <Field auto isFullWidth name="facilities" component="select" label="Facilities" labelKey="name" valueKey="id" options={facilities} isMulti isHorizontal />
      </Container>
    </EditView>
  );
};

export default EditForm;
