import { Field } from "@builtbypixel/nucleus";

const FAQs = () => {
    return (
        <Field
            name="faqs"
            component="repeater"
            singleName="Question"
            layout="vertical"
            fieldArray={[{
                label: "Question",
                component: "text",
                name: "question"
            },
                {
                    label: "Answer",
                    component: "richtext",
                    name: "answer"
                },]}
        />
    )
}

export default FAQs;