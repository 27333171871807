import React, {useEffect, useState} from 'react';
import { Field, EditView, useHttp } from '@builtbypixel/nucleus';

import setup from './setup';
import Sections from './components/Sections';
import {Box, Container} from "@chakra-ui/react";

const EditForm = () => {
    const Http = useHttp();
    const [destinations, setDestinations] = useState();
    const [countries, setCountries] = useState();
    const [dealTypes, setDealTypes] = useState();

    useEffect(() => {
        Http.get('/destinations').then(res => setDestinations(res.data.data));
        Http.get('/countries').then(res => setCountries(res.data.data));
        Http.get('/deal-types').then(res => setDealTypes(res.data.data));
    }, [Http]);

  return (
    <EditView setup={setup} isFullWidth={false}>
      <Container
          p={{ base: '10px', sm: '15px' }}
          pb={{ base: '100px', sm: '100px' }}
          w='100%'
          variant='card'
          rounded='none'
          maxWidth='none'
      >
        <Box display='flex' flexDir='row' gridGap='3'>
            <Field
                auto
                isFullWidth
                name="title"
                component="text"
                label="Title"
                isRequired
            />
        </Box>
          <Box display='flex' flexDir='row' gridGap='3'>
              <Field
                  auto
                  isFullWidth
                  name="destinations"
                  component="select"
                  label="Destinations"
                  valueKey="id"
                  labelKey="label"
                  isMulti
                  isHorizontal
                  isRequired
                  options={destinations}
              />
              <Field
                  auto
                  isFullWidth
                  name="countries"
                  component="select"
                  label="Countries"
                  isMulti
                  isHorizontal
                  isRequired
                  valueKey="id"
                  labelKey="value"
                  options={countries}
              />
              <Field
                  auto
                  isFullWidth
                  name="deal_types"
                  component="select"
                  label="Deal Type"
                  isMulti
                  isHorizontal
                  isRequired
                  valueKey="id"
                  labelKey="name"
                  options={dealTypes}
              />
          </Box>
          <Box display='flex' flexDir='row' gridGap='3'>
              <Field
                  auto
                  isFullWidth
                  name="excerpt"
                  component="richtext"
                  label="Excerpt"
                  isRequired
              />
          </Box>
          <Box display='flex' flexDir='row' gridGap='3'>
              <Field
                  auto
                  isFullWidth
                  name="banner_image"
                  component="file"
                  label="Banner Image"
                  isRequired
              />
          </Box>

          <Box display='flex' flexDir='row' gridGap='3'>
              <Field
                  auto
                  isFullWidth
                  name="blog"
                  component="repeater"
                  layout="vertical"
                  isRequired
                  singleName="Section"
                  fieldArray={[
                      {
                          component: Sections
                      }
                  ]}
              />
          </Box>
      </Container>
    </EditView>
  );
};

export default EditForm;
