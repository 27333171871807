/* eslint-disable */
const links = [
  {
    group: 'Deals',
    items: [
      {
        title: 'Deals',
        href: '/deals',
      },
      {
        title: 'Excursions',
        href: '/excursions'
      }
    ],
  },
  {
    group: 'Users',
    link: '/users',
    items: []
  },
  {
    group: 'Bookings',
    link: '/bookings',
    items: []
  },
  {
    group: 'Enquiries',
    items: [
      {
        title: 'Callbacks',
        href: '/callbacks'
      },
      {
        title: 'Holiday Enquiries',
        href: '/holiday-enquiries'
      },
      {
        title: 'Itinerary Enquiries',
        href: '/itinerary-builder-enquiries'
      },
      {
        title: 'Deal Enquiries',
        href: '/deal-enquiries'
      }
    ]
  },
  {
    group: 'Payments',
    link: '/payments',
    items: []
  },
  {
    group: 'Blogs',
    link: '/blogs',
    items: []
  },
  {
    group: 'Setup',
    items: [
      {
        title: 'Pages',
        href: '/pages'
      },
      {
        title: 'Referees',
        href: '/referrers'
      },
      {
        title: 'Banners',
        href: '/banners'
      },
      {
        title: 'Countries',
        href: '/countries'
      },
      {
        title: 'Cities',
        href: '/cities'
      },
      {
        title: "Categories",
        href: '/categories'
      },
      {
        title: 'Sub Categories',
        href: '/sub-categories'
      },
      {
        title: 'Cruise Lines',
        href: '/cruise-lines'
      },
      {
        title: 'Cruise Line Page Details',
        href: '/cruise-line-page-details'
      },
      {
        title: 'Ships',
        href: '/ships'
      },
      {
        title: 'Hotels',
        href: '/hotels'
      },
      {
        title: 'Search Tags',
        href: '/search-tags'
      },
      {
        title: 'Deal Types',
        href: '/deal-types'
      },
      {
        title: 'Activity Categories',
        href: '/activity-categories'
      }
    ],
  },
  {
    group: 'Seo',
    link: '/seo',
    items: []
  },
];

export default links;
