import React from 'react';
import { Container } from '@chakra-ui/react';
import { EditView, Field } from '@builtbypixel/nucleus';

import setup from './setup';
import PaymentDetails from './sections/PaymentDetails';
import BookingInfo from './sections/BookingInfo';
import DocumentInfo from './sections/DocumentInfo';
import ExcursionInfo from './sections/ExcursionInfo';
import TravellerInfo from './sections/TravellerInfo';

const EditForm = () => {
  return (
    <EditView setup={setup} isFullWidth={false}>
      <Container
        p={{ base: '10px', sm: '15px' }}
        w='100%'
        variant='card'
        rounded='none'
        maxWidth='none'
      >
        <h2>Deal Details</h2>
        <br />

        <Field
          auto
          isFullWidth
          name='deal_id'
          component='text'
          label='Deal ID'
          disabled
        />
        <BookingInfo />
        <DocumentInfo />
        <ExcursionInfo />
        <TravellerInfo />

        <PaymentDetails />
      </Container>
    </EditView>
  );
};

export default EditForm;
