import {useWatch} from "react-hook-form";
import {useMemo} from "react";

import SelectSection from './SelectSection';
import Paragraph from './Paragraph';
import Image from './Image';
import ImgPara from './ImgPara';
import Instagram from './Instagram';

const getSection = (SectionType) => {
    switch (SectionType) {
        case "Instagram":
            return Instagram;
        case "Paragraph-Image":
        case "Image-Paragraph":
            return ImgPara(SectionType === "Paragraph-Image");
        case "Image":
            return Image;
        case "Paragraph":
            return Paragraph;
        default:
            return SelectSection;
    }
}

const Sections = ({ parentName: name }) => {
    const SectionType = useWatch({ name: `${name}.section_type.name` });

    const Section = useMemo(() => getSection(SectionType), [SectionType]);

    return <Section name={name} />
}

export default Sections;