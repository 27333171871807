import {useWatch} from "react-hook-form";
import {Field} from "@builtbypixel/nucleus";
import {Flex, Grid} from "@chakra-ui/react";

const Date = ({ option, dateIndex, optionIndex, ...props }) => {

    return (
        <>
            <Flex flexDir="column" borderBottom="1px solid black" pl={optionIndex !== 0 ? "8px" : undefined}>
                <Flex flexDir="column" opacity={optionIndex === 0 ? 1 : 0}>
                    <Field
                        auto
                        isFullWidth
                        component="date"
                        name={`month_dates.0.dates.${dateIndex}.date_from`}
                        placeholder="Select month"
                        dateFormat="MMMM YYYY"
                        label="Date From"
                    />
                    <Field
                        auto
                        isFullWidth
                        component="date"
                        name={`month_dates.0.dates.${dateIndex}.date_to`}
                        placeholder="Select month"
                        dateFormat="MMMM YYYY"
                        label="Date To"
                    />
                </Flex>
                <h4><strong>{option.name}</strong></h4>
                <br />

                <Field
                    auto
                    isFullWidth
                    component="text"
                    name={`month_dates.0.dates.${dateIndex}.indoor_price`}
                    placeholder="Price Per Person"
                    label="Inside"
                />
                <Field
                    auto
                    isFullWidth
                    component="text"
                    name={`month_dates.0.dates.${dateIndex}.outdoor_price`}
                    placeholder="Price Per Person"
                    label="Outside"
                />
                <Field
                    auto
                    isFullWidth
                    component="text"
                    name={`month_dates.0.dates.${dateIndex}.balcony_price`}
                    placeholder="Price Per Person"
                    label="Balcony"
                />
                <Field
                    auto
                    isFullWidth
                    component="text"
                    name={`month_dates.0.dates.${dateIndex}.suite_price`}
                    placeholder="Price Per Person"
                    label="Suite"
                />
            </Flex>
        </>
    )
}

const MonthRepeater = ({ parentName, ...props }) => {
    const index = parseInt(parentName.split(".")[3], 10);
    const options = useWatch({ name: 'options' });

    if ((index % 2) === 1) {
        return (<Grid gridTemplateColumns={`repeat(2, 1fr)`} rowGap={"16px"}>
            {options.map((option, opIndex) => {
                return (<Date dateIndex={index - 1 + opIndex} optionIndex={opIndex} option={option} />)
            })}
        </Grid>)
    }

    return null;
}

const Dates = () => {
    return (
            <Field
                auto
                isFullWidth
                component="repeater"
                layout={"vertical"}
                name={`month_dates.0.dates`}
                canRender={(index) => (index % 2) === 1}
                customAppend={(append) => { append({}); append({}) }}
                fieldArray={[{
                    component: MonthRepeater,
                }]}
            />
    )
};

export default Dates;