import React from 'react';
import { LinkButton, ListWrapper } from '@builtbypixel/nucleus';
import { Button } from '@chakra-ui/react';

import setup from './setup';

// Extra Menu appears next to the create button.  You can call any component you wish.
// const extraMenu = () => {
//    return (
//       <Flex>
//          <Flex>fdgdsgfddf</Flex>
//       </Flex>
//    )
// }

const extraMenu = () => null;

const List = () => {
  const columns = [
    {
      Header: 'Name',
      accessor: 'name',
      id: 'edit',
      Cell: ({ row }) => (
        <LinkButton to={`${setup.model}/edit/${row.original.slug}`}>
          <Button variant='link' variantColor='secondary' rounded='md'>
            {row.original.name}
          </Button>
        </LinkButton>
      ),
    },
    {
      Header: 'slug',
      accessor: 'slug',
    },
  ];

  return <ListWrapper setup={setup} columns={columns} extraMenu={extraMenu} />;
};

export default List;
