import { Field } from "@builtbypixel/nucleus";

const Instagram = ({ name }) => {
    return (
        <Field
            name={`${name}.content`}
            auto
            isFullWidth
            component="text"
            label="Instagram Code"
        />
    )
}

export default Instagram;